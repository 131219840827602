import config from '@/config';
import * as esriLoader from "esri-loader";
import { EsriStation } from '@/lib/types';
import * as _ from 'underscore';


export type EsriSymbol = any;

export const getEsriLayerSymbol = async (layer): Promise<Array<EsriSymbol>> => {
    const [ lang, SimpleMarkerSymbol ] = await esriLoader.loadModules(["esri/core/lang", "esri/symbols/SimpleMarkerSymbol"]);

    function getSymbolObject(symbol: any) {
        let symbolObj;

        try {
            symbolObj = SimpleMarkerSymbol.fromJSON(symbol);
        } catch(e) {
            symbolObj = symbol.clone();
        }
        return symbolObj;
    }

    if (!layer.renderer) return [];

    if (layer.renderer.symbol) {
        const symbol = getSymbolObject(layer.renderer.symbol);
        const symbols: EsriSymbol[] = [symbol];
        return symbols;

    } else if (layer.renderer.infos && (layer.renderer.infos.length > 0)) {
        const symbols: Array<EsriSymbol> = [];
        for (let i = 0; i < layer.renderer.infos.length; i++) {
            const symbol = getSymbolObject(layer.renderer.infos.symbol);
            symbols.push(lang.clone(symbol));
        }
        return symbols;

    } else {
        return [];
    }
};

export const convertEsriSymbolToHTMLNode = async (symbol: EsriSymbol): Promise<HTMLElement> => {
    const [ symbolUtils ] = await esriLoader.loadModules(["esri/symbols/support/symbolUtils"]);
    const htmlEl = await symbolUtils.renderPreviewHTML(symbol);
    return htmlEl;
};

export const queryEsriLayer = async (layerUrl, queryObj): Promise<any> => {
    const [ QueryTask, Query ] = await esriLoader.loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);
    const queryTask = new QueryTask({ url: layerUrl });
    const query = new Query(queryObj);

    return queryTask.execute(query)
    .then(results => results.features);
}

// Determines whether station is opaque or not(faded-out) and emphasized or not. Possible values:
// 0_0: not opaque, not emphasized.
// 0_1: not opaque, emphasized
// 1_0: opaque, not emphasized.
// 1_1: opaque, emphasized.
export const getSentinelsLayerRendererValueExpression = (showSentinels: boolean, visiblePrograms: string[]): string => {
    return `
        var opaqueValue = '0'
        var emphasizedValue = '0'
        var opaqueVal = False

        var programsArray = ${JSON.stringify(visiblePrograms)}
        var featurePrograms = Split($feature.Measuring_Programs, ',') // start from one because string starts with comma
        for(var idx in featurePrograms) {
            opaqueVal = opaqueVal || IIf(IndexOf(programsArray, featurePrograms[idx]) > -1, True, False)
        }
        opaqueValue = IIf(opaqueVal, '1', '0')

        var showSentinels = Boolean(${showSentinels})
        emphasizedValue = When(
            !showSentinels, '0',
            $feature.Sentinel_Station == 'Y', '1',
            '0'
        )

        return Concatenate([opaqueValue, emphasizedValue], '_')
    `;
}

// Get Esri Stations with given embayment id
export const getEsriStationsForEmbaymentId = async (embayId: string): Promise<EsriStation[]> => {
    const queryObj = {
        where: `Site_number = ${embayId}`,
        outFields: ['*']
    };
    const results = await queryEsriLayer(config.map.layers.stations.url, queryObj);
    return results.map(res => res.attributes)
}
