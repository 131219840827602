import localForage from 'localforage';
import { WiskiStation, WiskiStationBasicInfo } from './types';
import { TsBasicInfo, WiskiStationParamTimeSeries, WiskiStationTimeseries } from './types/timeseries.types';


// ========= Cache Version ========
export const setCacheVersion = async (version: number): Promise<number> => {
    return localForage.setItem<number>('cache-version', version);
}

export const getCacheVersion = async (): Promise<number | null> => {
    return localForage.getItem<number>('cache-version');
}



// ========= All Stations ========
export const cacheStations = async (stations: Array<WiskiStationBasicInfo>): Promise<Array<WiskiStationBasicInfo>> => {
    return localForage.setItem<Array<WiskiStationBasicInfo>>(`stations`, stations);
}

export const getStationsCache = async (): Promise<Array<WiskiStationBasicInfo> | null> => {
    return localForage.getItem<Array<WiskiStationBasicInfo>>(`stations`);
}

export const cacheStationsByEmbay = async (embayId: string, stations: Array<WiskiStationBasicInfo>): Promise<Array<WiskiStationBasicInfo>> => {
    return localForage.setItem<Array<WiskiStationBasicInfo>>(`embay-${embayId}-stations`, stations);
}

export const getStationsByEmbayCache = async (embayId: string): Promise<Array<WiskiStationBasicInfo> | null> => {
    return localForage.getItem<Array<WiskiStationBasicInfo>>(`embay-${embayId}-stations`);
}


// ========= Individual Stations ========
export const cacheStation = async (stationId: string, params: WiskiStation): Promise<WiskiStation> => {
    return localForage.setItem<WiskiStation>(`station-${stationId}`, params);
}

export const getStationCache = async (stationId: string): Promise<WiskiStation | null> => {
    return localForage.getItem<WiskiStation>(`station-${stationId}`);
}

export const updateStationCache = async (stationId: string, params: WiskiStation): Promise<WiskiStation> => {
    const cache = await getStationCache(stationId);
    if (!cache) {
        return cacheStation(stationId, params);
    } else {
        const newCache = {...cache, ...params};
        return cacheStation(stationId, newCache);
    }
}

// ========= Station Timeseries Basic Info ========
export const cacheStationTimeseriesBasicInfo = async (stationPath: string, tsInfo: Array<TsBasicInfo>): Promise<Array<TsBasicInfo>> => {
    return localForage.setItem<Array<TsBasicInfo>>(`station-${stationPath}-timeseries-basic-info`, tsInfo);
}

export const getStationTimeseriesBasicInfoCache = async (stationPath: string): Promise<Array<TsBasicInfo> | null> => {
    return localForage.getItem<Array<TsBasicInfo>>(`station-${stationPath}-timeseries-basic-info`);
}

// ========= Station Timeseries Values for multiple params ========
export const cacheStationTimeseries = async (stationId: string, paramId: string, ts: WiskiStationParamTimeSeries): Promise<WiskiStationTimeseries> => {
    return localForage.setItem<WiskiStationTimeseries>(`station-${stationId}-timeseries`, {[paramId]: ts});
}

export const getStationTimeseriesCache = async (stationId: string): Promise<WiskiStationTimeseries | null> => {
    return localForage.getItem<WiskiStationTimeseries>(`station-${stationId}-timeseries`);
}

export const getStationTimeseriesForParamCache = async (stationId: string, paramId: string): Promise<WiskiStationParamTimeSeries | null> => {
    const tsCache = await localForage.getItem<WiskiStationTimeseries>(`station-${stationId}-timeseries`);
    if (!tsCache) return null;

    return tsCache[paramId];
}

export const updateStationTimeseriesCache = async (stationId: string, ts: WiskiStationTimeseries): Promise<WiskiStationTimeseries> => {
    const cache = await getStationTimeseriesCache(stationId);
    const newCache = {...(cache || {}), ...ts};
    return localForage.setItem<WiskiStationTimeseries>(`station-${stationId}-timeseries`, newCache);
}

//========= Clear Cache ============
export const clearAllCache = async () => {
    return localForage.clear();
}