



























import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import { mapState } from "vuex";
import { isMobile } from "./lib/utils";
import { clearAllCache, getCacheVersion, setCacheVersion } from "./lib/localCache";
import config from "./config";

export default Vue.extend({
  name: "App",
  components: { Header },
  data: () => ({
    wiskiErrorModalOpen: false,
  }),
  computed: mapState(["dataDrawerOpen"]),
  watch: {
    dataDrawerOpen: function (open) {
      if (open && !this.$store.state.wiskiInitialized) {
        this.wiskiErrorModalOpen = true;
        this.$store.commit("toggleDataDrawer", false);
      }
    },
  },
  mounted() {
    this.checkLocalCacheVersion();
    this.$store.commit("setWiskiInitialized");
    this.$store.dispatch("loadInitialStationConstants");
  },
  methods: {
    isMobile() {
      return isMobile();
    },
    checkLocalCacheVersion() {
      getCacheVersion().then(async (savedVersion) => {
          const versionToUse = config.cacheVersion;
          if (!savedVersion || (versionToUse !== savedVersion)) {
              await clearAllCache();
              setCacheVersion(versionToUse);
          }
      });
    },
  },
});
