var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-settings"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto","lg":"5","md":"6","sm":"12"}},[(_vm.colorizeOpts == 'program')?_c('div',{staticClass:"setting-programs"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltips.delay,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"setting-label"},'span',attrs,false),on),[_vm._v("Legend")])]}}],null,false,4020289583)},[_c('span',[_vm._v(_vm._s(_vm.tooltips.legend))])]),_c('span',{staticClass:"setting-programs-list"},_vm._l((_vm.monitoringPrograms),function(program,idx){return _c('span',{key:program.program,class:{active: program.active},on:{"click":function($event){return _vm.selectProgram(idx)}}},[_c('div',{staticClass:"program-circle",style:({background: _vm.programsColors[program.program], opacity: .7})}),_vm._v(" "+_vm._s(program.program)+" ")])}),0)],1):_vm._e(),(_vm.colorizeOpts == 'quality')?_c('div',{staticClass:"setting-dquality"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltips.delay,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"setting-label"},'span',attrs,false),on),[_vm._v("Legend")])]}}],null,false,4020289583)},[_c('span',[_vm._v(_vm._s(_vm.tooltips.legend))])]),_c('span',{staticClass:"setting-dquality-list"},_vm._l((_vm.dqualities),function(dquality,idx){return _c('span',{key:dquality.dquality,class:{active: dquality.active},on:{"click":function($event){return _vm.selectDquality(idx)}}},[_c('div',{staticClass:"program-circle",style:({background: _vm.qualityColors[dquality.dquality]})}),_vm._v(" "+_vm._s(dquality.dquality)+" ")])}),0)],1):_vm._e(),(_vm.colorizeOpts == 'site' && _vm.stationsColors)?_c('div',{staticClass:"setting-stations"},[_c('span',{staticClass:"setting-label"},[_vm._v("Legend")]),_c('span',{staticClass:"setting-stations-list"},_vm._l((_vm.embaymentStations),function(station,idx){return _c('span',{key:("station-" + idx),class:{inactive: !station.active},style:({background: _vm.stationsColors[station.station.Station_number], display: station.hide ? 'none' : 'initial'}),on:{"click":function($event){return _vm.selectStation(idx)}}},[_vm._v(" "+_vm._s(station.station.Station_name)+" ")])}),0)]):_vm._e()]),_c('v-col',{attrs:{"cols":"auto","lg":"3","md":"4","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltips.delay,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"setting-label"},'span',attrs,false),on),[_vm._v("Colorize:")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltips.colorize))])]),_c('v-radio-group',{staticClass:"setting-colorize small-radio",attrs:{"row":""},model:{value:(_vm.colorizeOpts),callback:function ($$v) {_vm.colorizeOpts=$$v},expression:"colorizeOpts"}},[_c('v-radio',{attrs:{"label":'Station',"value":'site'}}),_c('v-radio',{attrs:{"label":'Data Quality',"value":'quality'}}),_c('v-radio',{attrs:{"label":'Program',"value":'program'}})],1)],1),_c('v-col',{attrs:{"cols":"auto","lg":"2","md":"6","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltips.delay,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"setting-label"},'span',attrs,false),on),[_vm._v("Scale:")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltips.scale))])]),_c('v-radio-group',{staticClass:"setting-scale small-radio",attrs:{"row":""},model:{value:(_vm.scale),callback:function ($$v) {_vm.scale=$$v},expression:"scale"}},[_c('v-radio',{attrs:{"label":'Log',"value":'log'}}),_c('v-radio',{attrs:{"label":'Linear',"value":'linear'}})],1)],1),_c('v-col',{attrs:{"cols":"auto","lg":"1","md":"4","sm":"12"}},[_c('div',{staticClass:"setting-legend"},[(_vm.showingTimeTrendLegend)?_c('div',[_c('span',{staticClass:"legend-average"}),_c('span',[_vm._v("Average")])]):_vm._e(),(_vm.showingTimeTrendLegend)?_c('div',[_c('span',{staticClass:"legend-trend"}),_c('span',[_vm._v("Trend")])]):_vm._e()])]),_c('v-col',{attrs:{"cols":"auto","lg":"1","md":"2","sm":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltips.delay,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"setting-reset setting-label",on:{"click":_vm.resetZoom}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-expand-horizontal")]),_vm._v(" Reset Zoom ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltips.zoom))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }