import { EsriStation, WiskiStation } from '@/lib/types';
import * as _ from 'underscore';
import { TsBareData, TsData } from '../types/timeseries.types';


export const parseEsriStationPrograms = (station: EsriStation): string[] | null => {
    // First character is a ',', that's why we slice it off.
    if (!station) return null;
    return station.Measuring_Programs ? station.Measuring_Programs.split(',').slice(1) : null;
}

export const parseEsriStationYears = (station: EsriStation): {p1: string[]; p2: string[]; p3: string[]} => {
    // First character is a ',', that's why we slice it off.
    return {
        p1: station.Years_Program_1 ? station.Years_Program_1.split(',').slice(1) : [],
        p2: station.Years_Program_2 ? station.Years_Program_2.split(',').slice(1) : [],
        p3: station.Years_Program_3 ? station.Years_Program_3.split(',').slice(1) : []
    }
}

export const parseEsriStationMonths = (station: EsriStation): number[] | null => {
    if (!station) return null;
    // First character is a ',', that's why we slice it off.
    const monthsStr =  station.Months ? station.Months.split(',').slice(1) : null;
    return monthsStr ? monthsStr.map(st => parseInt(st)) : null;
}

export const parseEsriStationParameters = (station: EsriStation): string[] | null => {
    if (!station) return null;
    // First character is a ',', that's why we slice it off.
    return station.Parameters ? station.Parameters.split(',').slice(1) : null;
}

export const getEsriStationYearsForPrograms = (station: EsriStation, programs: string[]): string[] => {
    const stationPrograms = parseEsriStationPrograms(station);
    const allStationYears = parseEsriStationYears(station);
    if (!stationPrograms) return [];
    let years: string[] = [];

    stationPrograms.forEach((stationProgram,idx) => {
        if(programs.indexOf(stationProgram) !== -1) {
            if (idx === 0) years = [...years, ...allStationYears.p1];
            if (idx === 1) years = [...years, ...allStationYears.p2];
            if (idx === 2) years = [...years, ...allStationYears.p3];
        }
    });

    return years;
}

export const getMergedStationsPrograms = (stations: Array<EsriStation>): string[] => {
    let programs: string[] = [];
    stations.forEach((station: EsriStation) => {
        const progs = parseEsriStationPrograms(station);
        if (!progs) return;
        programs = [...programs, ...progs];
    });
    return _.uniq(programs);
}

export const getMergedStationsParams = (stations: Array<EsriStation>): string[] => {
    let params: string[] = [];
    stations.forEach((station: EsriStation) => {
        const progs = parseEsriStationParameters(station);
        if (!progs) return;
        params = [...params, ...progs];
    });
    return _.uniq(params);
}

export const getMergedStationsMonths = (stations: Array<EsriStation>): number[] => {
    let months: number[] = [];
    stations.forEach((station: EsriStation) => {
        const mons = parseEsriStationMonths(station);
        if (!mons) return;
        months = [...months, ...mons];
    });
    return _.uniq(months);
}

export const getMergedStationsYearsForPrograms = (stations: Array<EsriStation>, programs: string[]): string[] => {
    let years: string[] = [];
    stations.forEach((station: EsriStation) => {
        const ys = getEsriStationYearsForPrograms(station, programs);
        if (!ys.length) return;
        years = [...years, ...ys];
    });
    return _.uniq(years);
}

export const formatWiskiTimeseriesData = (bareData: TsBareData, columns: Array<string>, stationId: string): TsData => {
    if (!bareData.length) {
        return [];
    }

    const timeStampColIdx = columns.findIndex(c => c === 'timestamp');
    const valueColIdx = columns.findIndex(c => c === 'value');
    const valueQualityIdx = columns.findIndex(c => c === 'value.quality');
    const measuringProgramIdx = columns.findIndex(c => c === 'measuringprogram');

    const formatted: TsData = bareData.map(colsValues => ({
        stationId,
        timestamp: timeStampColIdx !== -1 ? colsValues[timeStampColIdx] : null,
        value: valueColIdx !== -1 ? colsValues[valueColIdx] : null,
        quality: valueQualityIdx !== -1 ? colsValues[valueQualityIdx] : null,
        program: measuringProgramIdx !== -1 ? colsValues[measuringProgramIdx] : null
    }));

    return formatted;
}

export const getWiskiTimeseriesQualityString = (quality: number): string => {
    return quality < 90 ? 'Good' : ((quality >= 90 && quality < 130) ? 'Fair' : '');
}

// Mapping between param name in ESRI service to param name in Wiski Station, in case their different.
export const ESRI_TO_WISKI_PARAMS_CORRECTIONS = {
    'Water Temperature': 'Water Temperature',
    'Chlorophyll A': 'Chlorophyll-a'
}

export const WISKI_TO_ESRI_PARAMS_CORRECTIONS = {
    'Water Temperature': 'Water Temperature',
    'Chlorophyll-a': 'Chlorophyll A'
}

export const convertWiskiParamNameToParamPath = (paramName: string, wiskiStation: WiskiStation): string | null => {
    const paramInfo = wiskiStation.children?.find(paramsInfo => paramsInfo.name === paramName);
    return paramInfo ? paramInfo.bdPath : null;
}

