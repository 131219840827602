








import Vue from 'vue';
import DataDrawerHeader from '@/components/DataDrawer/DataDrawerContainer/DataDrawerHeader.vue';

export default Vue.extend({
    name: 'DataDrawerOpener',
    components: {
        DataDrawerHeader
    },
    props: ['map','mapView'],
})
