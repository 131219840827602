













import Vue from "vue";
import * as d3 from "d3";
import * as _ from 'underscore';
import config from '@/config';
import { endOfYear, getMonth, isAfter, isBefore, isValid, setYear, startOfYear, format, subDays, addDays } from 'date-fns'
import { ChartSettings, DataSettings, EsriStation, WiskiStation, } from "@/lib/types";
import { mapState } from "vuex";
import { WiskiStationTimeseries } from "@/lib/types/timeseries.types";
import { convertWiskiParamNameToParamPath, ESRI_TO_WISKI_PARAMS_CORRECTIONS, OLSRegression, getMean, getWiskiTimeseriesQualityString, getPValue} from "@/lib/utils";
import { RootState } from "@/store";

type ChartDataPoint = {
  timestamp: Date;
  value: any;
  stationId: string;
  program: string;
  quality: number;
};
type ChartData = Array<ChartDataPoint>;
type ChartDataWithFilterFlags = Array<ChartDataPoint & {
    chauvinetFiltered?: boolean;
    spatioTemporalFiltered?: boolean;
}>;

type TimeTrendLinePoint = {
  timestamp: number;
  trendValue: any;
  filteredOut: boolean;
};
type TimeTrendLineData = Array<TimeTrendLinePoint>;
type TimeTrendMeanPoint = {
    year: number;
    xMean: Date;
    mean: number;
};
type TimeTrendDataMean = Array<TimeTrendMeanPoint>;

let idleTimeout = null;

export default Vue.extend({
  name: "DataChartResults",
  data: () => ({
        // General config
        idleDelay: 350,
        publicPath: process.env.BASE_URL,
        debounceTimeout: null,

        // Svg, d3.js chart variables
        width: 0,
        height: 0,
        svg: null, //Add scales here
        graph: null, //svg but with clipPath. Add data points here
        tooltipDiv: null,
        xScale: null,
        yScale: null,
        yAxisLabel: '',
        brushFn: null,
        zoomExtent: null,

        // Chart Data
        unfilteredData: [],
        chartData: [],
        timeTrendLine: [],
        timeTrendMeans: [],
        stationIdToNameMap: {},

        // From Chart Settings (chart filters)
        groupBy: "",
        chartScale: 'linear',
        stationNumbers: [],
        qualities: [],
        selectedPrograms: [],

        // From Data Filters (Explore The Data Section)
        param: '',
        yearRange: [],
        months: [],
        monitoringPrograms: []
  }),
  props: [
      "timeSeriesData",
      "wiskiStations",
      "stationsColors",
      "qualityColors",
      "programsColors",
      "showTimeTrend"
    ], //First 2 arrays Must have same number of elements and the element at a specific index in one match the same index at the other.
  computed: mapState(["chartSettings", "dataFilters"]),
  watch: {
    timeSeriesData: {
      immediate: true,
      handler: function ( newV: Array<WiskiStationTimeseries>, oldV: Array<WiskiStationTimeseries>) {
        this.formatDataFromStations();
      },
    },
    wiskiStations: {
      immediate: false,
      handler: function (stations: Array<WiskiStation>) {
            this.formatDataFromStations();
      },
    },
    showTimeTrend() {
        if (this.showTimeTrend) {
            this.updateTimeTrend();
        } else {
            this.resetTimeTrend();
        }
    },
    chartData: function (data) {
        this.updateAxis();
        this.updateGraphData();
        this.$emit('noData', !data.length);
        this.$emit('enoughForTimeTrend', this.determineIfEnoughYearsForTimeTrend());
        if (this.showTimeTrend) this.updateTimeTrend();

        // this.resetTimeTrend();
    },
    timeTrendLine: function() {
        this.updateTimeTrendGraphData();
    },
    "dataFilters.years": {
        immediate: false,
        handler: function (years: DataSettings["years"]) {
            this.yearRange = years as any;
            this.formatDataDebounced();
        }
    },
    "dataFilters.months": {
        immediate: false,
        handler: function (months: DataSettings["months"]) {
            this.months = months as any;
            this.formatDataDebounced();
        }
    },
    "dataFilters.params": {
        immediate: true,
        handler: function (paramName: DataSettings["params"]) {
            this.param = paramName;
            this.formatDataDebounced();
        },
    },
    "dataFilters.monitoringPrograms": {
        immediate: true,
        handler: function (monitoringPrograms: DataSettings["monitoringPrograms"]) {
            this.monitoringPrograms = monitoringPrograms as any;
            this.formatDataDebounced();
        }
    },
    "chartSettings.monitoringPrograms": {
        immediate: true,
        handler: function ( newV: ChartSettings["monitoringPrograms"]) {
            this.selectedPrograms = newV as any;
            this.formatDataDebounced();
        }
    },
    "chartSettings.qualities": {
        immediate: true,
        handler: function ( newV: ChartSettings["qualities"]) {
            this.qualities = newV as any;
            this.formatDataDebounced();
        }
    },
    "chartSettings.stations": {
        immediate: true,
        handler: function ( newV: ChartSettings["stations"]) {
            const stationNumbers = newV.map((st: EsriStation) => st.Station_number.toString());
            this.stationNumbers = stationNumbers as any;
            this.formatDataDebounced();
        }
    },
    "chartSettings.scale": function ( newV: ChartSettings["scale"]) {
        this.chartScale = newV;
        this.showTimeTrend && this.updateTimeTrend()
        this.updateAxis();
        this.updateGraphData();
    },
    "chartSettings.colorize": {
        immediate: true,
        handler: function ( newV: ChartSettings["colorize"]) {
            this.groupBy = newV;
            this.updateGraphData();
        }
    },
    "chartSettings.reset": function ( newV: ChartSettings["reset"]) {
        this.zoomExtent = null;
        this.updateGraphExtent(null, true);
    },
  },
  mounted() {
    this.initGraph();
    this.tooltipDiv = d3.select('.chart-tooltip');
  },
  methods: {
    idled() {
        idleTimeout = null;
    },
    formatDataDebounced() {
        if (this.debounceTimeout) clearTimeout(this.debounceTimeout as any); 

        this.debounceTimeout = setTimeout(() => {
            this.formatDataFromStations();
        }, 100) as any;
    },
    formatDataFromStations() {
        let paramName = this.param;
        paramName = ESRI_TO_WISKI_PARAMS_CORRECTIONS[paramName] || paramName;

        let flattenedData: ChartData = [];
        if (!this.monitoringPrograms.length) {
            this.chartData = [];
            return;
        }

        let yAxisLabel;
        this.wiskiStations.forEach((station: WiskiStation, idx: number) => {
            const paramPath = convertWiskiParamNameToParamPath(paramName, station);

            if ( !paramPath || !this.timeSeriesData[idx] || !this.timeSeriesData[idx][paramPath]) {
                return;
            }
            station.shortName && (this.stationIdToNameMap[station.shortName] = station.name);

            yAxisLabel = (this.timeSeriesData as WiskiStationTimeseries)[idx][paramPath].ts_unitsymbol;
            const timeSeriesData = (this.timeSeriesData as WiskiStationTimeseries)[idx][paramPath].formattedData;
            flattenedData = [...flattenedData, ...timeSeriesData];
        });

        this.yAxisLabel = yAxisLabel;

        // Filter by monitoring programs data filter
        flattenedData = flattenedData.filter(d => (this.monitoringPrograms as any).indexOf(d.program) !== -1)
        this.unfilteredData = flattenedData as any;

        const visibleStations = _.uniq((this.unfilteredData as ChartDataPoint[]).map(dp => dp.stationId));
        this.$store.commit("setChartVisibleData", {stationNums: visibleStations});

        // Apply chart filters.
        this.chartData = this.filterData(this.unfilteredData.slice()) as any;
    },
    filterData(unfilteredData: ChartData) {
        let paramName = this.param;
        paramName = ESRI_TO_WISKI_PARAMS_CORRECTIONS[paramName] || paramName;

        const firstDateOfYear = startOfYear(setYear(new Date(), this.yearRange[0]));
        const lastDateOfYear = endOfYear(setYear(new Date(), this.yearRange[1]));
        const monthsNumbers: number[] | null = this.months.length ? this.months : null;
        const progAbbrToName = (this.$store.state as RootState).stationConstants?.abbrToName;
        const selectedPrograms = this.selectedPrograms.map(p => progAbbrToName ? progAbbrToName[p] : p);


        const filteredData = unfilteredData
            .filter(d => { // Filter by stations selections (buttons)
                return this.wiskiStations.length < 1 || (this.stationNumbers as any).indexOf(d.stationId as string) !== -1
            })
            .filter(d => { // Filter by year range
                const date = new Date(d.timestamp);//parseISO(d.timestamp);
                if (!isValid(firstDateOfYear) || !isValid(lastDateOfYear)) return true;
                return isAfter(date, firstDateOfYear) && isBefore(date, lastDateOfYear)
            })
            .filter(d => { // Filter by months
                const date = new Date(d.timestamp);
                return monthsNumbers ?  monthsNumbers.indexOf(getMonth(date)) !== -1 : false
            })
            .filter(d => { // Filter by quality
                const qualityStr = getWiskiTimeseriesQualityString(d.quality);
                return (this.qualities as any).indexOf(qualityStr) !== -1;
            })
            .filter(d => { // Filter by selected programs
                return selectedPrograms.findIndex(p => p === d.program) !== -1;
            })

        return filteredData;

    },
    determineIfEnoughYearsForTimeTrend() {
        const startYear = parseInt(this.yearRange[0]);
        const endYear = parseInt(this.yearRange[1]);
        if ((endYear - startYear + 1) < 5) return false;

        const groupedByYear = _.groupBy(this.chartData, (d: ChartDataPoint) => new Date(d.timestamp).getFullYear());
        let numYears = 0;
        Object.keys(groupedByYear).forEach(year => {
            if ((groupedByYear[year] as Array<ChartDataPoint>).some(p => !!p.value)) {
                numYears = numYears + 1;
            }
        });
        return numYears >= 5;
    },
    updateTimeTrend() {
        // Calculate time trend and assign results to component variables
        const trendData = this.calculateTimeTrendAndApplyTrendFilters(this.chartData, this.chartScale === 'log');
        this.timeTrendLine = trendData.trendLine as any;
        this.timeTrendMeans = trendData.trendMeans as any;

        const circles = (this.graph as any).selectAll(".dot").data(this.chartData, (d: ChartDataPoint) => `${d.timestamp}-${d.value}`);
        circles.style('opacity', (d: any) => d.spatioTemporalFiltered ? .1 : 1)

        this.$emit('trendInfo', {
            confidence: trendData.trendInfo.confidence,
            trend: trendData.trendInfo.slope,
            parameter: this.param,
            yearRange: this.yearRange
        })
    },
    resetTimeTrend() {
        this.timeTrendMeans = [];
        this.timeTrendLine = [];

        const circles = (this.graph as any).selectAll(".dot").data(this.chartData, (d: ChartDataPoint) => `${d.timestamp}-${d.value}`);
        circles.style('opacity', 1);

        this.$emit('timeTrendReset');
    },
    calculateTimeTrendAndApplyTrendFilters(chartData: ChartData, isLogScale: boolean): {trendLine: TimeTrendLineData; trendMeans: TimeTrendDataMean; trendInfo: {slope: number; confidence: number}} {
        // Add Chauvinet's outlier and spatio-temporal bias flags to all points in the data
        // let filteredData = this.addChauvinetFilterFlagForTimeTrend(chartData, curParam !== 'temp');

        // Remove spatio-temporal bias outliers and values less than 1 if using log scale.
        let filteredData = chartData;
        filteredData = this.addSpatioTemporalFilterFlagForTimeTrend(filteredData)
            .filter(d => !d.spatioTemporalFiltered)
            .filter(d => isLogScale ? d.value >= 1 : true)

        // Calculate annual means.
        // If log scale enable, calculate geometric means by using log10 of the values.
        const groupedByYear = _.groupBy(filteredData, (d: ChartDataPoint) => new Date(d.timestamp).getFullYear());
        const means: Array<{year: number; xMean: Date; mean: number}> = [];

        Object.keys(groupedByYear).forEach(year => {
            const yMean = getMean(groupedByYear[year].map((d: ChartDataPoint) => isLogScale ? Math.log10(d.value) : d.value));
            const xMean = new Date(getMean(groupedByYear[year].map((d: ChartDataPoint) => new Date(d.timestamp).getTime())));

            means.push({
                year: parseInt(year),
                xMean,
                mean: isLogScale ? 10**yMean : yMean
            });
        });

        const xs = means.map(d => d.year);
        const ys = means.map(d => d.mean);

        const regression = OLSRegression(ys, xs);
        const regressionPoints: TimeTrendLineData = [
            {
                timestamp: startOfYear(setYear(new Date(), xs[0])).getTime(),
                trendValue: (xs[0]*regression.slope) + regression.intercept,
                filteredOut: false
            },
            {
                timestamp: endOfYear(setYear(new Date(), xs[xs.length-1])).getTime(),
                trendValue: (xs[xs.length-1]*regression.slope) + regression.intercept,
                filteredOut: false
            },
        ];

        const pValue = getPValue(ys, xs, regression);

        return {
            trendLine: regressionPoints,
            trendMeans: means,
            trendInfo: {
                slope: regression.slope,
                confidence: pValue
            }
        };
    },
    addSpatioTemporalFilterFlagForTimeTrend(allData: ChartDataWithFilterFlags): ChartDataWithFilterFlags {
        // const data = allData.slice();
        const data = allData;
        data.forEach(d => d.timestamp = new Date(d.timestamp).getTime() as any);
        const groupedByStation: {[stationNum: number]: ChartData} = _.groupBy(data, 'stationId');

        const isEmbaymentView = Object.keys(groupedByStation).length > 1;
        if (!isEmbaymentView) return allData;

        const embayMinYear = new Date(data.reduce((min, p: ChartDataPoint) => p.timestamp < min ? p.timestamp : min, allData[0].timestamp)).getFullYear();
        const embayMaxYear = new Date(data.reduce((max, p: ChartDataPoint) => p.timestamp > max ? p.timestamp : max, allData[0].timestamp)).getFullYear();
        const embayNumYears = embayMaxYear - embayMinYear + 1;

        const shouldFilterStations: {[stationNum: string]: boolean} = {};

        Object.keys(groupedByStation).forEach(stationNum => {
            const stationData = groupedByStation[stationNum];

            const groupedByYear: {[year: number]: ChartData} = _.groupBy(stationData, d => new Date(d.timestamp).getFullYear());
            let siteNumYears = 0;
            Object.keys(groupedByYear).forEach(year => (groupedByYear[year].length > 0) && siteNumYears++);

            const minYear = new Date(stationData.reduce((min, p: ChartDataPoint) => p.timestamp < min ? p.timestamp : min, stationData[0].timestamp)).getFullYear();
            const maxYear = new Date(stationData.reduce((max, p: ChartDataPoint) => p.timestamp > max ? p.timestamp : max, stationData[0].timestamp)).getFullYear();

            // If following criteria is false, add spatioTemporal filter flag for all chartData in station:
            // station must have been sampled for at least the first 1/4 and the last 1/4 of the timeseries within its embayment,
            // and have been sampled for at least 50% of the total number of years of data in the embayment.
            if ((minYear < (embayMinYear + embayNumYears / 4)) && (maxYear > (embayMaxYear - embayNumYears / 4)) && (siteNumYears >= (embayNumYears/2))) {
                shouldFilterStations[stationNum] = false;
            } else {
                shouldFilterStations[stationNum] = true;
            }
        });

        allData.forEach(d => d.spatioTemporalFiltered = shouldFilterStations[d.stationId]);
        return allData;
    },

    initGraph() {
      // set the dimensions and margins of the graph
      const margin = { top: 10, right: 100, bottom: 30, left: 100 },
        width = window.innerWidth - margin.left - margin.right,
        height = (window.innerHeight * .5) - margin.top - margin.bottom;

      // append the svg object to the body of the page
      const svg = d3
        .select("#data-results")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      //A clipPath is used to avoid displaying the circle outside the chart area.
      svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);

      const graph = svg.append("g").attr("clip-path", "url(#clip)");

      // Add the brush feature using the d3.brush function
    //   this.brushFn = d3.brushX().extent([[0, 0], [width, height], ]).on("end", (e) => this.updateGraphExtent(e));
      this.brushFn = d3.brush().on("end", this.onZoomChange)
      graph.append("g").attr("id", "brush").call(this.brushFn);

      this.svg = svg;
      this.graph = graph;
      this.width = width;
      this.height = height;

      this.initAxis();
    },
    initAxis() {
        if (!this.svg) return;
        const svg = this.svg as any;

        // Add X axis
        const x = d3.scaleTime()
            .domain(d3.extent( (this.chartData as ChartData).map( (d: ChartDataPoint) => new Date(d.timestamp))))
            .range([0, this.width])
            .nice()

        const xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat("%b"));
        svg.append("g")
            .attr("id", "xAxis")
            .attr("color", "gray")
            .attr("transform", "translate(0," + this.height + ")")
            .call(xAxis);

        // Add Y axis
        const y = d3.scaleLinear()
            .domain(d3.extent((this.chartData as ChartData).map((d) => d.value)))
            .range([this.height, 0])
            .nice()

        const yAxis = d3.axisLeft(y);
        svg.append("g")
            .attr("id", "yAxis")
            .attr("color", "gray")
            .call(yAxis);

        // add y axis legend
        d3.select('#yAxis')
            .append('text')
            .attr('id', 'yAxisLabel')
            // .attr('stroke', 'gray')
            .attr('fill', 'gray')
            .attr('x', -25)
            .attr('y', 2)
            .attr('dy', 0)
            .attr('font-size', '14px')
            .text(this.yAxisLabel);

        this.xScale = x;
        this.yScale = y;

    },
    updateGraphData() {
        if (!this.svg || !this.graph) return;
        const graph = this.graph as any;

        const x = this.xScale as any;
        const y = this.yScale as any;

        const colorScaleFn = this.getColorScaleFn();

        const circles = graph.selectAll(".dot").data(this.chartData, (d: ChartDataPoint) => `${d.timestamp}-${d.value}`);
        let newCircles = circles.enter();
        const oldCircles = circles.exit();

        const RADIUS = 4;

        // circles to remove
        oldCircles
            .transition()
            .duration(200)
            .attr("r", 0)
            .style('opacity', .2)
            .remove();

        // circles to add
        newCircles = newCircles
            .append("circle")
            .filter((d: ChartDataPoint) => !!d.value && !!d.timestamp)
            .attr("class", (d: ChartDataPoint) => "dot " + `dot${d.stationId}`)
            .attr("cx", (d: ChartDataPoint) => x(new Date(d.timestamp)))
            .attr("cy", (d: ChartDataPoint) => y(d.value))
            .attr("r", 0)
            .style("fill", (d: ChartDataPoint) => this.colorScaleValue(d, colorScaleFn))

        newCircles
            .transition()
            .duration(200)
            .attr("r", RADIUS)

        newCircles
            .on("mouseover", (e, d: ChartDataPoint) => this.dataPointMouseOver(e, d))
            .on("mouseleave", this.dataPointMouseOut);

        // circles to update
        circles
            .style("fill", (d: ChartDataPoint) => this.colorScaleValue(d, colorScaleFn))
            .transition()
            .duration(400)
            .delay((d, i) => i / this.chartData.length * 500)  // Dynamic delay (i.e. each item delays a little longer)
            .attr("r", RADIUS)
            .attr("cx", (d: ChartDataPoint) => x(new Date(d.timestamp)))
            .attr("cy", (d: ChartDataPoint) => y(d.value))
    },
    updateTimeTrendGraphData() {
        if (!this.svg || !this.graph) return;
        const graph = this.graph as any;

        const x = this.xScale as any;
        const y = this.yScale as any;

        // Trend Line
        graph.selectAll('.time-trend-line').remove();

        if (this.timeTrendLine.length) {
            const line = d3.line()
                .x((d: TimeTrendLinePoint) => x(d.timestamp))
                .y((d: TimeTrendLinePoint) => y(d.trendValue));

            graph.append('path')
                .attr('class', 'time-trend-line')
                .datum(this.timeTrendLine)
                .attr('d', line);
        }

        // Trend Annual Means
        const squares = graph.selectAll(".trend-dot").data(this.timeTrendMeans, (d: TimeTrendMeanPoint) => `${d.year}`);
        let newSquares = squares.enter();
        const oldSquares = squares.exit();

        const SIZE = 16;

        // squares to remove
        oldSquares
            .transition()
            .duration(200)
            .attr("width", 0)
            .attr("height", 0)
            .style('opacity', .2)
            .remove();

        // squares to add
        newSquares = newSquares
            .append("rect")
            .attr("class", (d: TimeTrendMeanPoint) => "trend-dot " + `dot${d.year}`)
            // .attr("x", (d: TimeTrendMeanPoint) => x(startOfYear(setYear(new Date(), d.year))))
            .attr("x", (d: TimeTrendMeanPoint) => x(d.xMean))
            .attr("y", (d: TimeTrendMeanPoint) => y(d.mean))
            .attr("width", 0)
            .attr("height", 0)
            .style('fill', 'red')

        newSquares
            .transition()
            .duration(200)
            .attr("width", SIZE)
            .attr("height", SIZE)

        newSquares
            .on("mouseover", (e, d: TimeTrendMeanPoint) => this.timeTrendMouseOver(e, d))
            .on("mouseleave", this.timeTrendMouseOut);

        // circles to update
        squares
            .transition()
            .duration(400)
            .delay((d, i) => i / this.timeTrendMeans.length * 500)  // Dynamic delay (i.e. each item delays a little longer)
            .attr("width", SIZE)
            .attr("height", SIZE)
            // .attr("x", (d: TimeTrendMeanPoint) => x(startOfYear(setYear(new Date(), d.year))))
            .attr("x", (d: TimeTrendMeanPoint) => x(d.xMean))
            .attr("y", (d: TimeTrendMeanPoint) => y(d.mean))

    },
    updateAxis() {
        const xExtent = d3.extent( (this.chartData as ChartData).map( (d: ChartDataPoint) => new Date(d.timestamp))),
            yExtent = d3.extent((this.chartData as ChartData).map((d) => d.value)),
            yRange = yExtent[1] - yExtent[0];

        // Generate new scale and update x axis
        const x = d3.scaleTime()
            // Add padding to the x-axis
            .domain([subDays(xExtent[0], 100), addDays(xExtent[1], 100)])
            .range([0, this.width])
            .nice();

        // if (this.zoomExtent) {
        //     const xExtent = [(this.zoomExtent as any)[0][0], (this.zoomExtent as any)[1][0]].map(x.invert, x);
        //     x.domain(xExtent);
        // }

        // Generate new scale and update y axis
        const y = (this.chartScale === 'linear' ? d3.scaleLinear() : d3.scaleSymlog())
            // Add padding to the y-axis
            .domain([yExtent[0] - (yRange * .1), yExtent[1] + (yRange * .1)])
            .range([this.height, 0])
            .nice();

        const xAxis = d3.axisBottom(x).tickFormat(this.getCustomTimeFormat);
        d3.selectAll('g#xAxis')
        .transition()
        .duration(1000)
        .call(xAxis);

        const yAxis = d3.axisLeft(y);
        d3.selectAll('g#yAxis')
        .transition()
        .duration(1000)
        .call(yAxis);

        d3.select('#yAxisLabel').text(this.yAxisLabel);

        this.xScale = x;
        this.yScale = y;
    },
    onZoomChange(event?: any) {
        // this.resetTimeTrend();
        const extent = event ? event.selection : null;
        this.updateGraphExtent(extent, false);
    },
    updateGraphExtent(extent?: any, reset?: boolean) {
        if (!this.xScale || !this.yScale || !this.brushFn || !this.graph || !this.svg) return;

        // Update Brush extent
        const xScale = this.xScale as any;
        const yScale = this.yScale as any;

        // If no selection, back to initial coordinate. Otherwise, update X axis domain
        if (!extent) {
            if (!idleTimeout && !reset) {
                this.updateTimeTrendGraphData();
                idleTimeout = setTimeout(this.idled, this.idleDelay) as any;
                return;
            }
            xScale.domain(d3.extent( (this.chartData as ChartData).map( (d: ChartDataPoint) => new Date(d.timestamp))))
            yScale.domain(d3.extent((this.chartData as ChartData).map((d) => d.value)));
        } else {
            this.zoomExtent = extent;
            const xExtent = [extent[0][0], extent[1][0]].map(xScale.invert, xScale);

            xScale.domain(xExtent);
            yScale.domain([extent[1][1], extent[0][1]].map(yScale.invert, yScale));
            (this.svg as any).select("#brush").call((this.brushFn as any).move, null);
        }

        this.xScale = xScale;
        this.yScale = yScale;

        // Update axis and circle position
        const xAxis = d3.select("#xAxis");
        xAxis.transition().duration(1000).call(d3.axisBottom(this.xScale));
        const yAxis = d3.select("#yAxis");
        yAxis.transition().duration(1000).call(d3.axisLeft(this.yScale));

        (this.graph as any)
            .selectAll(".dot")
            .transition()
            .duration(1000)
            .attr("cx", (d: ChartDataPoint) => xScale(new Date(d.timestamp)))
            .attr("cy", (d: ChartDataPoint) => yScale(d.value));
    
    },
    dataPointMouseOver(event: any, d: ChartDataPoint) {
        const stationName = this.stationIdToNameMap[d.stationId];

        (this.tooltipDiv as any).transition()		
            .duration(200)		
            .style("opacity", .9);		
        (this.tooltipDiv as any)
            .style("left", (event.pageX + 40) + "px")		
            .style("top", (event.pageY - 200) + "px");	

        (this.tooltipDiv as any).html(`
            Station Name: ${ stationName } <br/>
            Value: ${d.value} <br/>
            Date: ${format(new Date(d.timestamp), 'MM/dd/yyyy')} <br/>
            ${(d as any).spatioTemporalFiltered !== undefined && (d as any).spatioTemporalFiltered ? '<b>Not used in trend.</b>' : ''}
        `)
    },
    dataPointMouseOut() {
        (this.tooltipDiv as any).transition()		
            .duration(500)		
            .style("opacity", 0);	
    },
    timeTrendMouseOver(event: any, d: TimeTrendMeanPoint) {
        (this.tooltipDiv as any).transition()		
            .duration(200)		
            .style("opacity", .9);		
        (this.tooltipDiv as any)
            .style("left", (event.pageX) + "px")		
            .style("top", (event.pageY - 200) + "px");	

        (this.tooltipDiv as any).html(`
            Mean: ${d.mean.toFixed(3)} <br/>
            Year: ${d.year} <br/>
        `)
    },
    timeTrendMouseOut() {
        (this.tooltipDiv as any).transition()		
            .duration(500)		
            .style("opacity", 0);	
    },
    getColorScaleFn() {
      const groupName = this.groupBy as ChartSettings["colorize"];
      if (groupName === "site") {
            const stationsIds: string[] = this.wiskiStations.map( (st: WiskiStation) => st.shortName);
            const colors = stationsIds.map(stId => this.stationsColors[stId]);

            return d3
            .scaleOrdinal()
            .domain(stationsIds)
            .range(colors)
      } else if (groupName === 'program') {
            const progAbbrToName = (this.$store.state as RootState).stationConstants?.abbrToName;
            const programs = this.selectedPrograms.map(p => progAbbrToName ? progAbbrToName[p] : p);
            const colors = this.selectedPrograms.map(prog => this.programsColors[prog]);
            return d3
                .scaleOrdinal()
                .domain(programs)
                .range(colors);

      } else if (groupName === 'quality') {
            const colors = this.qualities.map(quality => this.qualityColors[quality]);
            return d3
                .scaleOrdinal()
                .domain(this.qualities)
                .range(colors);
      }
    },
    colorScaleValue(dataPoint: ChartDataPoint, colorScaleFn) {
        const groupName = this.groupBy as ChartSettings["colorize"];
        if (groupName === "site") {
            return colorScaleFn(dataPoint.stationId);
        } else if (groupName === "program") {
            return colorScaleFn(dataPoint.program);
        } else if (groupName === "quality") {
            const qualityStr = getWiskiTimeseriesQualityString(dataPoint.quality);
            return colorScaleFn(qualityStr);
        }
    },
    getCustomTimeFormat: (date: Date) => {
        const formatMillisecond = d3.timeFormat(".%L"),
        formatSecond = d3.timeFormat(":%S"),
        formatMinute = d3.timeFormat("%I:%M"),
        formatHour = d3.timeFormat("%I %p"),
        formatDay = d3.timeFormat("%a %d"),
        formatWeek = d3.timeFormat("%b %d"),
        formatMonth = d3.timeFormat("%B"),
        formatYear = d3.timeFormat("%Y");

        return (d3.timeSecond(date) < date ? formatMillisecond
            : d3.timeMinute(date) < date ? formatSecond
            : d3.timeHour(date) < date ? formatMinute
            : d3.timeDay(date) < date ? formatHour
            : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
            : d3.timeYear(date) < date ? formatMonth
            : formatYear)(date);
    },
  },
});
