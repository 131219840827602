








































import { EsriStation } from '@/lib/types';
import { convertDMS, parseEsriStationParameters, parseEsriStationPrograms } from '@/lib/utils';
import { RootState } from '@/store';
import Vue from 'vue'

export default Vue.extend({
    name: 'EsriStationPopover',
    data: () => {
        return {
            visible: false,
            popup: null,
            station: null,
            stationCoords: '',
            stationPrograms: [],
            stationParams: []
        }
    },
    methods: {
        show(el, station: EsriStation, popup: any) {
            const abbrs = (this.$store.state as RootState).stationConstants?.programsAbbrv || {};

            this.popup = popup;
            this.station = station as any;
            this.stationCoords = convertDMS(station.Latitude, station.Longitude);
            const progs = parseEsriStationPrograms(station)
            this.stationPrograms = progs ? progs.map(prog => abbrs[prog]) : [] as any;
            const params = parseEsriStationParameters(station);
            this.stationParams = params ? params : [] as any;

            return new Promise((resolve,reject) => {
                this.visible = true;
                resolve(this.$el)
            });
        },
        
        hide() {
            this.visible = false;
        },

        openStationData() {
            this.$store.commit('toggleDataDrawer', true);
            this.$store.dispatch('loadAndSelectStation', this.station);
            (this.popup as any).close();
        }
    },
    mounted() {
        document.body.appendChild(this.$el);
    },
})
