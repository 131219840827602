









































import Vue from 'vue';
import $ from 'jquery';
import DataDrawerContainer from './DataDrawerContainer/DataDrawerContainer.vue';
import { isMobile } from '@/lib/utils';

export default Vue.extend({
    name: 'DataDrawer',
    components: { DataDrawerContainer },
    props: ['map','mapView'],
    data() {
        return {
            height: window.innerHeight * .90,
            drawerY: 0,
            h: 400
        }
    },
    methods: {
        onResizeCallback(handle, x, y, width, height){
            this.drawerY = y;
            if (y > (this.height - 180)) {
                this.$store.commit('toggleDataDrawer', false);
                return false;
            }
        },
        isMobile() {
            return isMobile();
        }
    }
})
