

























































import Vue from 'vue';
import DataDrawerHeader from '@/components/DataDrawer/DataDrawerContainer/DataDrawerHeader.vue';
import DataFilters from '@/components/DataDrawer/DataDrawerContainer/DataFilters.vue';
import DataChartResults from '@/components/DataChart/DataChartResults.vue';
import DataChartSettings from '@/components/DataChart/DataChartSettings.vue';
import TimeTrendInfo from '@/components/DataChart/TimeTrendInfo.vue';
import { mapState } from 'vuex';
import { DataSettings, EmbaymentInfo, EsriStation, StationInfo, WiskiStation } from '@/lib/types';
import { getEsriStationsForEmbaymentId, getMergedStationsPrograms, WISKI_TO_ESRI_PARAMS_CORRECTIONS } from '@/lib/utils';
import { convertTsPathToParamPath, getStationsForEmbayment, getStationsParamsInfo, getStationTimeSeriesByParamsIds, getTimeSeriesBasicInfoByStationPath } from '@/lib/wiskiApi';
import { WiskiStationTimeseries } from '@/lib/types/timeseries.types';
import config from '@/config';
import { RootState } from '@/store';

export default Vue.extend({
    name: 'DataDrawerContainer',
    components: {
        DataDrawerHeader,
        DataFilters,
        DataChartResults,
        DataChartSettings,
        TimeTrendInfo
    },
    data: () => ({
        esriStations: [],
        wiskiStations: [],
        timeSeries: [],
        stationsColors: {},
        qualityColors: {},
        programsColors: {},
        embayment: null,

        enoughYearsForTrend: true, // Are there enough years selected in the filters to show time trend?
        contiguousMonthsForTrend: true, // Are months in the filters contiguous so that time trend can be shown?

        enableTimeTrend: false, //True if trend button was clicked.
        timeTrendResults: {
            confidence: 0,
            trend: 0,
            parameter: '',
            yearRange: [],
        },

        loading: true,
        loadingProgress: 0,
        currentEmbaymentId: '',
    }),
    props: ['map','mapView', 'drawerHeight'],
    computed: {
        ...mapState(['dataFilters', 'selectedStation', 'selectedEmbayment', 'stationActive']),
        showingTimeTrend: { // Whether time trend chart should be visible
            get: function() {
                return this.enableTimeTrend && this.enoughYearsForTrend && this.contiguousMonthsForTrend;
            }
        },
        disableTrendButton: {//Make trend button disabled
            get: function() {
                return !this.enoughYearsForTrend || !this.contiguousMonthsForTrend;
            }
        }
    },
    watch: {
        'dataFilters.months': function(months: DataSettings['months']) {
            const first = months[0];
            const areContiguous = months.every((m,i) => m === (i+first));
            this.contiguousMonthsForTrend = areContiguous
        },
        selectedStation: {
            immediate: true,
            handler: async function(station: StationInfo) {
                if (station.empty || !station.esri) return;
                this.embayment = null;
                this.esriStations = [station.esri] as any;
                await this.getWiskiInfoForStations(this.esriStations);
            },
        },
        selectedEmbayment: {
            immediate: true,
            handler: async function(emb: EmbaymentInfo) {
                if (!emb.esri) return;
                this.embayment = emb as any;
                this.currentEmbaymentId = emb.esri.attributes.EMBAY_ID.toString();

                if (!this.stationActive) {
                    this.esriStations = await getEsriStationsForEmbaymentId(emb.esri.attributes.EMBAY_ID.toString()) as any;
                    await this.getWiskiInfoForStations(this.esriStations);
                }
            }
        },
    },
    mounted() {
        this.setDataQualityColors();
    },
    methods: {
        async getWiskiInfoForStations(stations: Array<EsriStation>) {
            this.loading = true;
            this.loadingProgress = 0;

            // Assuming all stations are part of the same embayment!
            // 1) Get Wiski stations for the embayment. 
            // 2) Filter out stations not present in the ESRI service.
            const embaymentWiskiId = stations[0].WISKI_ID;
            let stationsForEmbayment = await getStationsForEmbayment(embaymentWiskiId.toString());
            stationsForEmbayment = stationsForEmbayment.filter(wiskiStation => stations.find(esriSt => esriSt.Station_name === wiskiStation.name));

            let wiskiStations: Array<WiskiStation | null> = [];

            // 3) Get More data (BasicData, containing a list of all available timeseries parameters) for all stations
            //      Used in step 5 below to filter out params.
            if (this.stationActive) {
                const stationWiski = stationsForEmbayment.find(st => st.name === stations[0].Station_name);
                const stationWiskiId = stationWiski ? stationWiski.id : null;
                if (!stationWiskiId) return;
                wiskiStations = await getStationsParamsInfo([stationWiskiId]);

            } else {
                const stationsWiskiIds = stationsForEmbayment.map(st => st.id);
                wiskiStations = await getStationsParamsInfo(stationsWiskiIds);
            }

            // 4) Fetch list of available Timeseries ids for the station.
            // 5) Filter params only available to station (determined from Esri field for station).
            //      a. Must first convert param names in esri field to bdPath fields (params' paths) in Wiski station.
            // 6) Then filter timeseries ids by comparing filtered bdPath in Wiski station's fields to tsPath fields in results from step #1.
            // 7) Using ids fields in filtered results (timeseries ids) to fetch all usefull timeseries for the station.

            const progressPerStation = 100/wiskiStations.length;

            const tsPromises = wiskiStations.map(async wiskiStation => {
                if (!wiskiStation || !wiskiStation.id || !wiskiStation.children) return null;
                const esriStation = stations.find(esriSt => esriSt.Station_number.toString() === wiskiStation.shortName);

                //4
                // const timeSeriesBasicData = await getTimeSeriesBasicInfoByStationPath(wiskiStation.bdPath as string)
                return getTimeSeriesBasicInfoByStationPath(wiskiStation.bdPath as string)
                .then(timeSeriesBasicData => {
                    if (!wiskiStation.children) return null;

                    this.loadingProgress = (this.loadingProgress + (progressPerStation/4));
                    if (!timeSeriesBasicData) return null;

                    // 5
                    const esriParamNames = esriStation?.Parameters? esriStation?.Parameters.split(',') : [];
                    const wiskiParamsPresentInEsri = wiskiStation.children
                        .filter(paramInfo => esriParamNames && esriParamNames.length && esriParamNames?.indexOf(WISKI_TO_ESRI_PARAMS_CORRECTIONS[paramInfo.name] || paramInfo.name) !== -1)

                    // 6 
                    const filteredTsIds: string[] = [];
                    wiskiParamsPresentInEsri.forEach(paramInfo => {
                        const tsData = timeSeriesBasicData.find(tsData => {
                            const paramPath =  convertTsPathToParamPath(tsData.tsPath);
                            return paramInfo.bdPath === paramPath;
                        }); 
                        if (tsData) filteredTsIds.push(tsData.id)
                    });

                    const stationNumber = esriStation?.Station_number.toString() || '';
                    return getStationTimeSeriesByParamsIds(stationNumber, filteredTsIds).then(res => { 
                        this.loadingProgress = (this.loadingProgress + (progressPerStation/4)*3);
                        return res;
                    }).catch(e => console.log("EE: ", e))

                }) .catch(e => console.log("tsbasic error: ", e));

            }).filter(n => !!n);

            const timeSeriesData = (await Promise.all(tsPromises)) as Array<WiskiStationTimeseries>;

            if (timeSeriesData.length !== wiskiStations.length) {
                console.error("TIMESERIES DATA LENGTH DO NOT MATCH WISKISTATIONS ARRAY LENGTH")
            }

            this.wiskiStations = wiskiStations as any;
            this.timeSeries = timeSeriesData as any;
            this.setStationsColors(this.wiskiStations);
            this.setProgramsColors(stations);

            this.loading = false;
        },
        setStationsColors(wiskiStations: WiskiStation[]) {
            const allColors = config.colors.map((color) => color.hex()).slice(1);
            const stationColors = {};
            wiskiStations.forEach((st,i) => stationColors[st.shortName as string] = allColors[i]);
            this.stationsColors = stationColors;
        },
        setDataQualityColors() {
            const allColors = ['#00B050', 'orange', 'red'];
            const qualityColors = {};
            (this.$store.state as RootState).stationConstants?.allQualities.forEach((q,i) => qualityColors[q] = allColors[i]);
            this.qualityColors = qualityColors;
        },
        setProgramsColors(stations: Array<EsriStation>) {
            const abbrs = (this.$store.state as RootState).stationConstants?.programsAbbrv || {};
            const allColors = config.colors.map((color) => color.hex()).slice(10);
            const programs = getMergedStationsPrograms(stations).map(p => abbrs[p]);
            const programsColors = {};
            programs.filter(p => !!p).forEach((p,i) => programsColors[p] = allColors[i]);
            this.programsColors = programsColors;
        },
        showTimeTrend() {
            this.enableTimeTrend = true;
        },
        hideTimeTrend() {
            this.enableTimeTrend = false;
        },
    }
})
