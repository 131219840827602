

























































































































import { isMobile } from '@/lib/utils';
import Vue from 'vue';

export default Vue.extend({
    name: 'DisclaimerContents',
    data() {
        return {
            publicPath: process.env.BASE_URL,
            tab: null
        }
    },
    methods: {
        closeModal() {
            this.$emit('close', true);
        }
    }
})
