
















































import { isMobile } from '@/lib/utils';
import Vue from 'vue';
import TimeTrendLegend from '@/components/DataChart/TimeTrendLegend.vue';

export default Vue.extend({
    name: 'TimeTrendInfo',
    props: ['info'],
    components: { TimeTrendLegend },
    data() {
        return {
            legendOpen: false
        }
    },
    methods: {
        hide: function() {
            this.$emit('hide');
        },
        isMobile() {
            return isMobile();
        },
        showLegend() {
            this.legendOpen = true;
        }
    },
    computed: {
        confidenceText: {
            get: function() {
                if (this.info.confidence >= 0.1) {
                    return 'Confident in no trend';
                } else if ((this.info.confidence < 0.1) && (this.info.confidence >= 0.05)) {
                    return 'Somewhat confident in trend';
                } else if ((this.info.confidence < 0.05) && (this.info.confidence > 0.01)) {
                    return 'Confident in trend';
                } else {
                    return '0.01 Very confident in trend';
                }
            }
        }
    }
});
