import distinctColors from 'distinct-colors';

type LayerConfig = {
    id: string;
    title: string;
    url: string;
};

export interface Config {
    production: boolean;
    version: string;
    cacheVersion: number; //Change this number to trigger an indexedb cache reset on app load. Should be changed when the structure of the cache (its keys and values formats) are changed.
    map: {
        basemap: string;
        center: [number, number];
        zoom: number;
        layers: {
            embayments: LayerConfig;
            subembayments: LayerConfig;
            towns: LayerConfig;
            stations: LayerConfig;
        };
    };
    api: {
        wiskiBaseURL: string;
        wiskiUsername: string;
        wiskiPass: string;
    };
    tooltips: {
        delay: number;
        messages: {
            [key: string]: string;
        };
    };
    colors: chroma.Color[];
}

const config: Config = {
    production: process.env.NODE_ENV === 'production',
    version: '1.0.5',
    cacheVersion: 1,
    map: {
        basemap: 'topo',
        center: [-70.303634, 41.70166],
        zoom: 11,
        layers: {
            embayments: {
                id: 'embayments',
                title: 'Watershed Boundaries',
                url: "https://gis-services.capecodcommission.org/arcgis/rest/services/wMVP/wMVP4/MapServer/1"
            },
            subembayments: {
                id: 'subembayments',
                title: 'Sub-embayments',
                url: "https://gis-services.capecodcommission.org/arcgis/rest/services/wMVP/wMVP4/MapServer/6"
            },
            towns: {
                id: 'towns',
                title: 'Town Lines',
                url: "https://gis-services.capecodcommission.org/arcgis/rest/services/wMVP/wMVP4/MapServer/2"
            },
            stations: {
                id: 'stations',
                title: 'Stations',
                url: "https://gis-services.capecodcommission.org/arcgis/rest/services/wMVP/WQMDP/MapServer/0",
            }
        }
    },
    api: {
        // wiskiBaseURL: 'http://localhost:8010/proxy/wiski/rest/0/',
        wiskiBaseURL: process.env.VUE_APP_WISKI_URL,
        wiskiUsername: process.env.VUE_APP_WISKI_USER,
        wiskiPass: process.env.VUE_APP_WISKI_PASS

    },
    tooltips: {
        delay: 300,
        messages: {
            mapOrgs: 'Click on the name of a monitoring organization below to toggle stations on and off.',
            mapLayers: 'Click to turn on and off GIS layers.',
            mapStationsBracket: 'Click to toggle on and off the sentinel stations.',
            mapStationsToggle: 'Stations with a nitrogen loading threshold documented by the Massachusetts Estuaries Project.',
            dataExplore: 'Use the adjoining dropdowns to fine tune your data selection. Zoom is available to change the data view without filtering the data.',
            dataParam: 'Water quality parameters measured at this location are displayed (all parameters may not be available).',
            dataProgram: ' Monitoring programs collecting data at this location are displayed (all programs may not be available).',
            dataYear: 'Years when data is available at this location are displayed (all years may not be available).',
            dataMonths: 'Months when data is available at this location are displayed (all months may not be available).',
            dataQuality: 'Data quality flags associated with measurements at this location are displayed (all data quality flags may not be available). More details on data quality flags can be found in About the Data.',
            dataExtent: 'See data for all stations within the embayment (Embayment).',
            dataLegend: 'Click to fade or highlight individual legend choice',
            dataColorize: 'Click to change the legend.',
            dataScale: 'Plot and analyze data on logarithmic or linear scales. Some parameters are better explored and analyzed on logarithmic scales because their data ranges are very large.',
            dataZoom: 'Zoom out without filtering the data',
        }
    },
    colors: distinctColors({count: 100})
};

export default config;