
















import { isMobile } from '@/lib/utils';
import Vue from 'vue';

export default Vue.extend({
    name: 'ResourcesModal',
    props: ['value'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            modalOpen: this.value,
            tab: null
        }
    },
    watch: {
        value(isOpen) {
            this.modalOpen = isOpen;
        },
        modalOpen(isOpen) {
            this.$emit('input', isOpen)
        }
    },
    methods: {
        isMobile() {
            return isMobile();
        }
    }
})
