




























import Vue from 'vue';
import EsriMap from '@/components/Map/EsriMap.vue';
import MapFilters from '@/components/Map/MapFilters.vue';
import DataDrawer from '@/components/DataDrawer/DataDrawer.vue';
import DataDrawerOpener from '@/components/DataDrawer/DataDrawerOpener.vue';

export default Vue.extend({
    name: 'Home',
    components: {
        EsriMap,
        MapFilters,
        DataDrawer,
        DataDrawerOpener
    },
    data: () => ({
        filterDrawer: false,
        map: null,
        mapView: null
    }),
    methods: {
        onMapInit(map) {
            this.map = map;
        },
        onMapViewInit(view) {
            this.mapView = view;
        }
    }
});
