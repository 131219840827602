









































































import config from '@/config';
import { ChartSettings, EmbaymentInfo, EsriStation, StationInfo } from '@/lib/types';
import { convertDMS, getEsriStationsForEmbaymentId, getMergedStationsPrograms } from '@/lib/utils';
import Vue from 'vue'
import { mapState } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import { RootState } from '@/store';

export default Vue.extend({
    name: 'DataDrawerHeader',
    components: { Carousel, Slide },
    props: ['map','mapView'],
    computed: mapState(['selectedStation','selectedEmbayment', 'dataDrawerOpen', 'chartSettings']),
    data: () => {
        return {
            stationCoords: '',
            embaymentCoords: '',
            embaymentStations: [],
            inactiveStations: [],
            embaymentPrograms: [],
            tooltips: {
                extent: config.tooltips.messages.dataExtent,
            }
        }
    },
    mounted() {
        // this.stationSelected((this.$store.state as RootState).selectedStation)
        // this.embaymentSelected((this.$store.state as RootState).selectedEmbayment);
    },
    watch: {
        selectedStation: {
            immediate: true,
            handler: function(newV: StationInfo, oldV: StationInfo) {
                this.stationSelected(newV);
            }
        },
        selectedEmbayment: {
            immediate: true,
            handler: async function(newV: EmbaymentInfo, oldV: EmbaymentInfo) {
                this.embaymentSelected(newV);
            }
        },
        'chartSettings.stations': function(newV: ChartSettings['stations']) {
            const stationNames = newV.map((st: EsriStation) => st.Station_name);
            this.inactiveStations = stationNames as any;
        },
        
    },
    methods: {
        toggleDataDrawer() {
            this.$store.commit('toggleDataDrawer', !this.$store.state.dataDrawerOpen);
        },
        showEmbaymentOnMap() {
            if (!this.mapView || !this.map) return;
            const embayLayer = (this.map as any).findLayerById(config.map.layers.embayments.id);
            if (!embayLayer || !this.$store.state.selectedEmbayment.esri) return;

            this.$store.commit('toggleDataDrawer', false);
            const embayment = this.$store.state.selectedEmbayment as EmbaymentInfo;

            const offsetExtent = embayment.esri?.geometry.extent; //embayment.esri?.geometry.extent.clone().offset(0,-4000,0);
            (this.mapView as any).goTo(offsetExtent, {duration: 2500});
            this.$emit('showMapClicked');
        },
        selectStation(station: EsriStation) {
            this.$store.commit('toggleDataDrawer', true);
            this.$store.dispatch('loadAndSelectStation', station);
        },
        stationSelected(station: StationInfo) {
            if (!station.esri) return;
            this.stationCoords = convertDMS(station.esri.Latitude, station.esri.Longitude);
        },
        async embaymentSelected(embayment: EmbaymentInfo) {
            if (!embayment.esri) return '';
            try {
                const lat = embayment.esri.geometry.centroid.latitude;
                const long = embayment.esri.geometry.centroid.longitude;
                this.embaymentCoords = convertDMS(lat, long);
            } catch(e) {
                this.embaymentCoords = '';
            }

            this.embaymentStations = await getEsriStationsForEmbaymentId(embayment.esri.attributes.EMBAY_ID.toString()) as any;

            const abbrs = (this.$store.state as RootState).stationConstants?.programsAbbrv || {};
            this.embaymentPrograms = getMergedStationsPrograms(this.embaymentStations).map(p => abbrs[p]) as any;
        },
        changeExtentToEmbayment() {
            this.$store.commit('selectStation', { wiski: null, esri: null});
            const curEmbayment = this.$store.state.selectedEmbayment as EmbaymentInfo;
            this.$store.commit('selectEmbayment', curEmbayment.esri)
        }
    }
})
