import config from '@/config';
import * as $ from 'jquery';
import * as _ from 'underscore';
import { getStationCache, getStationsByEmbayCache, cacheStationsByEmbay, updateStationCache, getStationTimeseriesCache, updateStationTimeseriesCache, getStationTimeseriesBasicInfoCache, cacheStationTimeseriesBasicInfo } from './localCache';
import { WiskiStation, WiskiStationBasicInfo } from './types';
import { TsBasicInfo, WiskiStationParamTimeSeries, WiskiStationTimeseries } from './types/timeseries.types';
import { formatWiskiTimeseriesData } from './utils';

const baseAjaxConfig = {
    cache: false,
    crossDomain: true,
    // dataType: 'xml',
    headers: {          
        Accept: "application/json; charset=utf-8",
        Authorization: `Basic ${btoa(config.api.wiskiUsername + ':' + config.api.wiskiPass)}`
    },
    xhrFields: {
        withCredentials: true
    },
}

// Example: retrieve the parameter path (eg: 149/1507/DON) from the tsPath (eg: 149/1507/DON/Sample_Results)
export function convertTsPathToParamPath(tsPath: string): string {
    return tsPath.split('/').slice(0, 3).join('/')
}

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
function formatTimeSeriesData(ts: Array<WiskiStationParamTimeSeries>): WiskiStationTimeseries {
    const timeSeriesHash: WiskiStationTimeseries = {};
    ts.forEach(ts => {
        const tsPath = convertTsPathToParamPath(ts.ts_path);
        const formattedData = formatWiskiTimeseriesData(ts.data as any, (ts.columns as any).split(','), ts.station_no.toString());

        // Only keep used data
        const tsData = {
            formattedData,
            parameter_name: ts.parameter_name,
            parameter_shortname: ts.parameter_shortname,
            ts_path: ts.ts_path,
            ts_name: ts.ts_name,
            ts_id: ts.ts_id,
            ts_shortname: ts.ts_shortname,
            ts_unitname: ts.ts_unitname,
            ts_unitsymbol: ts.ts_unitsymbol,
            site_id: ts.site_id,
            site_name: ts.site_name,
            site_no: ts.site_no,
            station_displayname: ts.station_displayname,
            station_id: ts.station_id,
            station_name: ts.station_name,
            station_no: ts.station_no,
            station_shortName: ts.station_shortName,
            station_site: ts.station_site
        }


        timeSeriesHash[tsPath] = tsData;
    });
    return timeSeriesHash;
}

function filterTimeSeriesBasicInfo(ts: Array<TsBasicInfo>): Array<TsBasicInfo> {
    return ts.filter(tinfo => tinfo.shortName === 'Sample_Results')
}

export const getStationsForEmbayment = async (embayId: string, useCache = true): Promise<Array<WiskiStationBasicInfo>> => {
    const cachedStations = await getStationsByEmbayCache(embayId);
    if (useCache && cachedStations) {
        return cachedStations;
    } 

    // const url = `${config.api.wiskiBaseURL}basicData/get?path=${embayId}&includeTimeSeries=true&includeInfoKeys=all&includeChildren=true`
    const url = `${config.api.wiskiBaseURL}basicData/getBasicDataList?idList=${embayId}&includeChildren=true&format=json`
    return new Promise((resolve, reject) => {
        $.ajax({ ...baseAjaxConfig, method: "GET", url })
        .done((result) => {
            //COMMENT FOLLOWING LINE TO SKIP PARSING XML
            // result = [(xmlToJson(result) as any).basicDataObjects.basicData];

            const stations: Array<WiskiStationBasicInfo> = result[0].children.length  ? result[0].children : [result[0].children];
            useCache && cacheStationsByEmbay(embayId, stations);
            resolve(stations);
        }).fail(reject)
    });
}

export const getStationsParamsInfo = async (stationIds: Array<string>, useCache = true): Promise<Array<WiskiStation | null>> => {
    const stationIdsToFetch: string[] = [];
    const resultsHash: {[stationId: string]: WiskiStation | null} = {};

    for (let i = 0; i < stationIds.length; i++) {
        const stationId = stationIds[i];
        const cachedStation = await getStationCache(stationId);

        if (cachedStation) {
            resultsHash[stationId] = cachedStation;
        } else {
            stationIdsToFetch.push(stationId)
        }
    }

    if (!stationIdsToFetch.length) {
        const response: Array<WiskiStation | null> = stationIds.map(id => resultsHash[id]);
        return Promise.resolve(response);
    }

    const url = `${config.api.wiskiBaseURL}basicData/getBasicDataList?idList=${stationIdsToFetch.join(',')}&includeChildren=true&format=json`
    return new Promise((resolve, reject) => {
        $.ajax({...baseAjaxConfig, method: "GET", url }).done((results: Array<WiskiStation>) => {

            //COMMENT FOLLOWING LINE TO SKIP PARSING XML
            // results = (xmlToJson(results) as any).basicDataObjects.basicData;
            results = _.isObject(results) && _.isArray(results) ? results : [results];

            if (results.length !== stationIdsToFetch.length)  {
                return reject("ERROR: getStationsParamsInfo");
            }

            stationIdsToFetch.forEach((stationId, i) => {
                resultsHash[stationId] = results[i];
                updateStationCache(stationId, results[i]);
            });

            const response: Array<WiskiStation | null> = stationIds.map(id => resultsHash[id]);
            resolve(response);

        }).fail(reject)
    });
}


export const getTimeSeriesBasicInfoByStationPath = async (stationPath: string, useCache = true): Promise<Array<TsBasicInfo> | null> => {
    const cachedTsInfo = await getStationTimeseriesBasicInfoCache(stationPath)
    if (useCache && cachedTsInfo) {
        return cachedTsInfo;
    } 

    const url = `${config.api.wiskiBaseURL}timeSeries/getTimeSeriesList?filter=${stationPath}&format=json`
    return new Promise((resolve, reject) => {
        $.ajax({ ...baseAjaxConfig, method: "GET", url })
        .done((result: Array<TsBasicInfo>) => {

            //COMMENT FOLLOWING LINE TO SKIP PARSING XML
            // result = (xmlToJson(result) as any).timeSeriesObjects.timeSeries;
            result = filterTimeSeriesBasicInfo(result);

            // const stations: Array<WiskiStationBasicInfo> = result[0].children;
            useCache && cacheStationTimeseriesBasicInfo(stationPath, result);
            resolve(result);
        }).fail(reject)
    });
}

export const getStationTimeSeriesByParamsIds = async (stationId: string, paramsIds: Array<string>, useCache = true): Promise<WiskiStationTimeseries | null> => {
    if (!paramsIds.length) return Promise.resolve(null);

    const cachedStation = await getStationTimeseriesCache(stationId);
    if (useCache && cachedStation) {
        return cachedStation;
    } 

    const url = `${config.api.wiskiBaseURL}timeSeries/getDataList?idList=${paramsIds.join(',')}&format=json`

    return new Promise((resolve, reject) => {
        $.ajax({...baseAjaxConfig, method: "GET", url, dataType: 'json' }).done((resp: Array<WiskiStationParamTimeSeries>) => {
            if (!resp) return resolve(null);

            const formattedTs = formatTimeSeriesData(resp);
            useCache && updateStationTimeseriesCache(stationId, formattedTs);
            resolve(formattedTs)
        }).fail(reject);
    });
}
 