



































































































import Vue from 'vue';
import { getEsriLayerSymbol, convertEsriSymbolToHTMLNode, getSentinelsLayerRendererValueExpression } from '@/lib/utils';
import { StationsConstants } from '@/lib/types';
import config from '@/config';
import { mapState } from 'vuex';

export default Vue.extend({
    name: 'MapFilters',
    props: [
    'value', // Value: Whether the Map Filters drawer is opened.
    'map',
    'mapView'
    ],
    data() {
        return {
            drawerOpened: this.value,

            monitoringPrograms: [''],
            selectedPrograms: [],
            monitoringProgramIcon: '',

            layers: [],
            selectedLayers: [],

            showSentinels: false,

            tooltips: {
                delay: config.tooltips.delay,
                orgs: config.tooltips.messages.mapOrgs,
                layers: config.tooltips.messages.mapLayers,
                stationBracket: config.tooltips.messages.mapStationsBracket,
                stationToggle: config.tooltips.messages.mapStationsToggle
            }
        }
    },
    computed: mapState(['stationConstants']),
    watch: {
        /**
         * Two-way data-binding to open/close drawer.
         */
        value(isOpen) {
            this.drawerOpened = isOpen;
        },
        async map(m) {
            const promises = this.map.layers.items.map(async (layer,idx) => {
                const symbols = await getEsriLayerSymbol(layer);
                const symbolEl = symbols.length ? await convertEsriSymbolToHTMLNode(symbols[0]) : null;

                const layerInfo = {
                    title: layer.title,
                    id: layer.id,
                    iconHTML: symbolEl ? symbolEl.innerHTML : '',
                    visible: layer.visible,
                    idx: idx
                };

                if (layer.id === config.map.layers.stations.id && layerInfo.iconHTML) {
                    this.monitoringProgramIcon = layerInfo.iconHTML
                }

                return layerInfo;
            });

            const layersInfo: Array<any> = await Promise.all(promises);
            this.layers = layersInfo as any;
            this.selectedLayers = layersInfo.filter(l => l.visible).map(l => l.idx) as any;
        },
        drawerOpened(isOpen) {
            this.$emit('input', isOpen);
        },
        stationConstants(newV: StationsConstants, oldV: StationsConstants) {
            this.monitoringPrograms = newV.monitoringPrograms;
            this.selectedPrograms = [...Array(newV.monitoringPrograms.length).keys()] as any;
        },
        showSentinels(enabled) {
            this.updateMapRenderer();
        },
        selectedPrograms(newV: number[]) {
            this.updateMapRenderer();
        },
        selectedLayers(newV: number[]) {
            this.layersSelected(newV);
        }
    },
    methods: {
        /**
         * When the selected layers are changed, make all selected layers visible
         * and all other layers invisible.
         */
        layersSelected(idxs) {
            const visibleLayers = idxs.map(idx => this.layers[idx]);
            const allLayers = this.map.layers;

            allLayers.items.forEach(layer => {
                const visible = visibleLayers.find(l => l.id === layer.id);
                layer.visible = !!visible;
            });
        },
        updateMapRenderer() {
            if (!this.map) return;
            const visiblePrograms: string[] = this.selectedPrograms.map(idx => this.monitoringPrograms[idx]);
            const stationLayer = this.map.findLayerById(config.map.layers.stations.id);
            const stationRenderer = stationLayer.renderer.clone();
            stationRenderer.valueExpression = getSentinelsLayerRendererValueExpression(this.showSentinels, visiblePrograms);
            stationLayer.renderer = stationRenderer;
        }
    }
})
