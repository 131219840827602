import { jStat } from 'jstat';

export type OLSRegressionResult = {
    slope: number;
    intercept: number;
    r2: number;
};

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
export const OLSRegression = (y: number[], x: number[]): OLSRegressionResult => {
    const lr: OLSRegressionResult = {
        slope: 0,
        intercept: 0,
        r2: 0
    };

    const n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr['intercept'] = (sum_y - lr.slope * sum_x)/n;
    lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
}

const _getSlopeSquareError = (ys: number[], xs: number[], regression: OLSRegressionResult) => {
    // sum((y_i-y_ifit)^2)
    const firstSum = ys.reduce((acc, cur, idx) => {
        const regressionValue = xs[idx] * regression.slope + regression.intercept;
        return acc + ((cur - regressionValue) ** 2);
    },0);

    // sum((x-x_mean)^2)
    const xMean = jStat.mean(xs);
    const secondSum = xs.reduce((acc, cur) => {
        return acc + ((cur - xMean) ** 2 );
    }, 0);

    const SE = Math.sqrt(firstSum / (ys.length - 2)) / Math.sqrt(secondSum);
    return SE;
}


export const getPValue = (ys: number[], xs: number[], regression: OLSRegressionResult) => {
    const slopeSE = _getSlopeSquareError(ys, xs, regression);
    const tStat = regression.slope / slopeSE;

    const pValue = 2 * (1 - jStat.studentt.cdf(Math.abs(tStat), ys.length - 2) );
    return pValue;
}

export function getMean(vals: number[]) {
    return jStat.mean(vals);
}

// export const addChauvinetFilterFlagForTimeTrend = (data: ChartData, removeNegatives: boolean): ChartDataWithFilterFlag => {
//     // Filter timetrend data using Chauvinet's Criterion
//     let result = [...data] as ChartDataWithFilterFlag;

//     let allData = data.map(d => d.value);
//     if (removeNegatives) {
//         allData.forEach((d, i) => {
//             if (d < 0) {
//                 result[i].filterOut = true
//             }
//         });
//     }

//     const finiteSum = allData.filter(d => Number.isFinite(d)).reduce((a, b) => a + b, 0);

//     const dMax = 1/(2 * finiteSum); // Maximum std. dev
//     const mean = d3.mean(allData);
//     const stdv = Math.sqrt(variance(x));

//     for (var i = 0; i < allData.length; i++) {
//         const d = Math.abs(allData[i] - mean)/stdv;
//         const e = erfc(d) //Complementary error function

//         if(dMax > e) { // Outliner. Filter out
//             result[i].filterOut = true;
//         } else {
//             result[i].filterOut = false;
//         }
//     };
// };