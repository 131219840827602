import { getStationsConstants } from '@/lib/esriApi';

import {
  EsriEmbayment,
  EsriStation,
  StationsConstants,
  EmbaymentInfo,
  StationInfo,
  WiskiStation,
  DataSettings,
  ChartSettings, 
  ChartVisibleData} from '@/lib/types';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export type RootState = {
  wiskiInitialized: boolean;
  dataDrawerOpen: boolean;
  stationConstants: StationsConstants | null;
  selectedStation: StationInfo;
  selectedEmbayment: EmbaymentInfo;
  stationActive: boolean;
  chartSettings: ChartSettings;
  dataFilters: DataSettings;
  chartVisibleData: ChartVisibleData;
}

export default new Vuex.Store<RootState>({
  state: {
    wiskiInitialized: false,
    dataDrawerOpen: false,
    stationConstants: null,
    selectedStation: {empty: true},
    selectedEmbayment: {empty: true},
    stationActive: false,
    chartSettings: {
      monitoringPrograms: [],
      stations: [],
      qualities: [],
      colorize: 'site' ,
      qapp: false,
      scale: 'linear',
      reset: false
    } as ChartSettings,
    dataFilters: {
      years: [],
      months: [],
      params: '',
      monitoringPrograms: [],
    } as DataSettings,
    chartVisibleData: {
      stationNums: []
    }
  },
  mutations: {
    setWiskiInitialized(state) {
      state.wiskiInitialized = true;
    },
    toggleDataDrawer(state, open: boolean) {
      state.dataDrawerOpen = open;
    },
    setInitialStationConstants(state, constants: StationsConstants) {
      state.stationConstants = constants;
      state.chartSettings.qualities = constants.allQualities
    },
    selectStation(state, station: {wiski?: WiskiStation; esri?: EsriStation}) {
      const newSelected = { ...state.selectedStation, ...station };
      state.stationActive = !!station.esri;
      state.selectedStation = newSelected;
      state.selectedStation.empty = !newSelected.esri;
    },
    selectEmbayment(state, embayment: { esri?: EsriEmbayment }) {
      const newSelected = { ...state.selectedEmbayment, ...embayment };
      state.selectedEmbayment = newSelected;
      state.selectedEmbayment.empty = !newSelected.esri;
    },
    setChartSettings(state, settings: Partial<ChartSettings>) {
      state.chartSettings = { ...state.chartSettings, ...settings};
    },
    setDataFilters(state, settings: Partial<DataSettings>) {
      state.dataFilters = { ...state.dataFilters, ...settings};
    },
    setChartVisibleData(state, visData: Partial<ChartVisibleData>) {
      state.chartVisibleData = { ...state.chartVisibleData,  ...visData };
    }
  },
  actions: {
    async loadInitialStationConstants(context) {
      const initConstants = await getStationsConstants(); 
      context.commit('setInitialStationConstants', initConstants);
    },
    async loadAndSelectStation(context, station?: EsriStation) {
      if (station) {
        context.commit('selectStation', { wiski: null, esri: station });
      } else {
        context.commit('selectStation', { wiski: null, esri: null });
      }
    },
    loadAndSelectEmbayment(context, embayment: EsriEmbayment ) {
      context.commit('selectEmbayment', { esri: embayment });
    }
  },
  modules: {
  }
})
