









































import Vue from 'vue'
import DisclaimerModal from '@/components/Header/Disclaimer/DisclaimerModal.vue';
import ResourcesModal from '@/components/Header/Resources/ResourcesModal.vue';
import config from '@/config';

export default Vue.extend({
    name: 'header',
    components: { DisclaimerModal, ResourcesModal },
    data: () => ({
        disclaimerOpened: true,
        resourcesOpened: false,
        version: config.version
    }),
    methods: {
      openResources() {
        window.open("https://gis-web-assets.capecodcommission.org/documents/Cape%20Cod%20Water%20Quality%20Monitoring%20Portal%20Methodology.pdf", '_blank');
      }
    }
})

