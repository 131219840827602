import config from '@/config';
import { EsriStation, StationsConstants } from './types';
import { parseEsriStationParameters, parseEsriStationPrograms, queryEsriLayer } from './utils';
import * as _ from 'underscore';

export const getStationsConstants = async (): Promise<StationsConstants> => {
    // Query all stations in Esri layer to determine all measuring programs
    const queryObj = {
        where: '1=1',
        outFields: ['Measuring_Programs', 'Parameters']
    }

    let programs: string[] = [];
    let params: string[] = [];
    const results: Array<Partial<EsriStation>> = await queryEsriLayer(config.map.layers.stations.url, queryObj);

    results.forEach((results: any) => {
        const station = results.attributes as Partial<EsriStation>;
        const progs = parseEsriStationPrograms(station as EsriStation);
        if (progs) programs = [...programs, ...progs];

        const prs = parseEsriStationParameters(station as EsriStation);
        if (prs) params = [...params, ...prs];

    });
    programs = _.uniq(programs);

    return Promise.resolve({
        allParameters: params,
        defaultParameter: 'Water Temperature',
        allQualities: ['Good', 'Fair'],
        monitoringPrograms: programs,
        programsAbbrv: {
            'SMAST Marine': 'SMAST',
            'Center for Coastal Studies': 'CCS',
            'Center for Coastal Studies Volunteers': 'CCSV',
            'Buzzards Bay Coalition': 'BBC',
            'WBNERR Baywatchers': 'WBNERR',
            'WBNERR System Wide Monitoring Program - Autosampler': 'WBNERR SWMP Autosampler',
            'WBNERR System Wide Monitoring Program - Grabs': 'WBNERR SWMP Grabs'
        },
        abbrToName: {
             'SMAST': 'SMAST Marine',
             'CCS':'Center for Coastal Studies',
             'CCSV':'Center for Coastal Studies Volunteers',
             'BBC':'Buzzards Bay Coalition',
             'WBNERR':'WBNERR Baywatchers',
             'WBNERR SWMP Autosampler':'WBNERR System Wide Monitoring Program - Autosampler',
             'WBNERR SWMP Grabs': 'WBNERR System Wide Monitoring Program - Grabs'
        }
    });
}