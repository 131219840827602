







import * as esriLoader from "esri-loader";
import config from '@/config';
import router from '@/router';
import $ from 'jquery';
import { EsriEmbayment, EsriStation, StationInfo } from '@/lib/types';
import { getSentinelsLayerRendererValueExpression } from '@/lib/utils';
import EsriStationPopover from '@/components/Map/EsriStationPopover.vue';
import { RootState } from '@/store';
import { SimpleFillSymbol } from 'esri/symbols';

export default {
  name: "EsriMap",
  components: { EsriStationPopover },
  data: () => ({
    map: null,
    mapView: null,
  }),
  mounted() {
    const self = this as any;

    esriLoader.loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/BasemapToggle",
      "dojo/domReady!"
    ])
    .then(([ Map, MapView, FeatureLayer, BasemapToggle ]) => {

          const map = new Map({ basemap: config.map.basemap });

          // Default view centered on Barnstable
          const view = new MapView({
            container: "viewDiv", // Reference to the DOM node that will contain the view
            map: map,
            zoom: config.map.zoom,
            center: config.map.center
          });

          const basemapGallery = new BasemapToggle({
            view,
            // nextBasemap: 'dark-gray'
            nextBasemap: 'satellite'
          });

          view.ui.add(basemapGallery, { position: 'top-left' });

          // Embayment featurelayer containing polygons
          const embayments = new FeatureLayer({
            id: config.map.layers.embayments.id,
            title: config.map.layers.embayments.title,
            url: config.map.layers.embayments.url,
            mode: FeatureLayer.MODE_ONDEMAND,
            visible: true,
            outFields: ["*"],
            renderer: {
                type: "simple",
                symbol: {
                  type: 'simple-fill',
                  color: [11,57,101,0.0],
                  style: "solid",
                  outline: {
                    color: '#769676',
                    width: 2
                }
              }
            }
          });

          // Subembayment featurelayer containing polygons
          const subembayments = new FeatureLayer({
            id: config.map.layers.subembayments.id,
            title: config.map.layers.subembayments.title,
            url: config.map.layers.subembayments.url,
            visible: false,
            renderer: {
                type: "simple",
                symbol: {
                  type: 'simple-fill',
                  color: 'transparent',
                  style: "solid",
                  outline: {
                    color: [128,137,232,0.45],
                    width: 2
                }
              }
            }
          });


          // Towns featureLayer containing polygons
          const towns = new FeatureLayer({
            id: config.map.layers.towns.id,
            title: config.map.layers.towns.title,
            visible: false,
            url: config.map.layers.towns.url,
            mode: FeatureLayer.MODE_ONDEMAND,
            outFields: ["*"],
            opacity: .4,
            // color: '#F06768',
            width: 3,
            renderer: {
                type: "simple",
                symbol: {
                  type: 'simple-fill',
                  color: 'transparent',
                  style: "solid",
                  outline: {
                    color: '#F06768',
                    width: 2
                }
              }
            }
          });

          // Station point renderer
          const symbols = [
            // Not opaque, not emphasized
            {
              id: '0_0',
              symbol: {
                type: "simple-marker",
                size: 5,
                color: [19, 132, 164, .3],
                outline: null  
              }
            },
            // Not opaque, emphasized
            {
              id: '0_1',
              symbol: {
                type: "simple-marker",
                size: 8,
                // color: [19, 132, 164, .3],
                color: [83, 168, 172, .3],
                outline: {
                  // color: [255, 0, 0, .2],
                  // color: [255, 255, 255, .2],
                  color: [161, 97, 161, .2],
                  width: 2
                }
              }
            },
            // Opaque, not emphasized
            {
              id: '1_0',
              symbol: {
                type: "simple-marker",
                size: 5,
                color: [19, 132, 164],
                outline: null  
              }
            },
            // Opaque, emphasized
            {
              id: '1_1',
              symbol: {
                type: "simple-marker",
                size: 8,
                // color: [19, 132, 164],
                color: [83, 168, 172],
                outline: {
                  // color: [255, 0, 0],
                  // color: [255, 255, 255],
                  color: '#A161A1',
                  width: 2
                }
              }
            }
          ]

          const stationsRender = {
            type: "unique-value",
            symbol: {
                type: "simple-marker",
                size: 6,
                color: "#1384A4",
                outline: null  
            },
            valueExpression: 'return "1_0"', //Initial value: opaque, not emphasized
            uniqueValueInfos: symbols.map((v) => {
              return {
                value: v.id,
                symbol: v.symbol
              }
            })
          }

          // Station featurelayer containing points
          const stations = new FeatureLayer({
            id: config.map.layers.stations.id,
            title: config.map.layers.stations.title,
            url: config.map.layers.stations.url,
            outFields: ['*'],
            popupTemplate: { content: (self.$refs.stationPopover as Vue).$el },
            renderer: stationsRender,
          });


          // Add featurelayers to map
          map.add(embayments);
          map.add(subembayments);
          map.add(towns);
          map.add(stations);

          view.on('layerview-create', () => { 
            self.map = map;
            self.mapView = view;
            self.$emit('map', map);
            self.$emit('mapView', view);
          });
      });
  },
  watch: {
    mapView() {
      const self = this as any;

      self.mapView.on('click', (e) => {
        self.mapView.hitTest(e).then(async (resp) => {
          if (!resp.results || !resp.results.length) return;

          // Test whether user clicked on a station and embayment
          const stationObj = resp.results.find(result => result.graphic.layer.id === config.map.layers.stations.id);
          const embayObj = resp.results.find(result => result.graphic.layer.id === config.map.layers.embayments.id);

          // If user clicked on a station
          if (stationObj) {
            const station = (stationObj.graphic.attributes as EsriStation);
            self.showStationPopover(station);

            if (!(self.$store.state as RootState).dataDrawerOpen) {
              self.$store.dispatch('loadAndSelectStation', station);
            }
          } else {
            self.$store.dispatch('loadAndSelectStation', null);
          }

          // If user clicked on embayment

          if (embayObj) {
            self.highlightSelected(embayObj);
            self.$store.dispatch('loadAndSelectEmbayment', embayObj.graphic);
            return;
          } else {
            self.highlightSelected(embayObj);
            self.$store.dispatch('loadAndSelectEmbayment', null);
          }
        })
      })
    }
  },
  methods: {
    showStationPopover(station: EsriStation) {
      const self = this as any;
      (self.$refs.stationPopover as any)
      .show(self.$refs.popoverDisplay, station, (self.mapView as any).popup)
      .then(el => {
          const stationLayer = (self.map as any).findLayerById(config.map.layers.stations.id);
          stationLayer.popupTemplate = { 
            title: `Station: ${station.Station_name}`,
            content: el
          }
      });
    },
    async highlightSelected(result) {
      const self = this as any;
      const [ SimpleFillSymbol ] = await esriLoader.loadModules(['esri/symbols/SimpleFillSymbol']);

      const symbol = new SimpleFillSymbol({
        color: [11,57,101,0.2],
        style: "solid",
        outline: {
          // color: '#A161A1',
          color: '#769676',
          width: 4
        }
      });

      (self.mapView as any).graphics.removeAll();

      if (result) {
        const selectionGraphic = result.graphic;
        selectionGraphic.symbol = symbol;
        (self.mapView as any).graphics.add(selectionGraphic);
      } 
    }
  }
}
