













































































































import { ChartSettings, ChartVisibleData, EmbaymentInfo, EsriStation, StationInfo } from '@/lib/types'
import { getEsriStationsForEmbaymentId, getMergedStationsPrograms, parseEsriStationPrograms } from '@/lib/utils'
import { RootState } from '@/store'
import config from '@/config';
import Vue from 'vue';
import { mapState } from 'vuex';
import * as _ from 'underscore';

export default Vue.extend({
    name: 'DataChartSettings',
    data: function() {
        return {
            colors: config.colors,
            showingTimeTrendLegend: this.showingTimeTrend,
            monitoringPrograms: [],
            dqualities: [],
            embaymentStations: [],
            colorizeOpts: 'site',
            qapp: false,
            scale: 'linear',

            tooltips: {
                delay: config.tooltips.delay,
                legend: config.tooltips.messages.dataLegend,
                colorize: config.tooltips.messages.dataColorize,
                scale: config.tooltips.messages.dataScale,
                zoom: config.tooltips.messages.dataZoom,
            },
        }
    },
    computed: {
        ...mapState(['selectedStation','selectedEmbayment', 'stationConstants', 'chartVisibleData']),
    },
    props: ['showingTimeTrend', 'stationsColors', 'qualityColors', 'programsColors'],
    watch: {
        showingTimeTrend(show: boolean) {
            this.showingTimeTrendLegend = show;
        },
        monitoringPrograms: {
            immediate: true,
            deep: true,
            handler: function (programs: Array<{active: boolean; program: string}>) {
                const activePrograms = programs.filter(p => p.active).map(p => p.program);
                this.$store.commit('setChartSettings', {monitoringPrograms: activePrograms});
            },
        },
        embaymentStations: {
            immediate: true,
            deep: true,
            handler: function (stations: Array<{active: boolean; station: StationInfo}>, old: Array<{active: boolean; station: StationInfo}>) {
                const activeStations = stations.filter(s => s.active).map(s => s.station);
                this.$store.commit('setChartSettings', {stations: activeStations});
            },
        },
        colorizeOpts: {
            immediate: true,
            handler: function(opts: ChartSettings['colorize']) {
                this.$store.commit('setChartSettings', {colorize: opts});
            }
        },
        scale: {
            immediate: true,
            handler: function(value: ChartSettings['scale']) {
                this.$store.commit('setChartSettings', {scale: value});
            }
        },
        dqualities: {
            immediate: true,
            handler: function(value: Array<{active: boolean; dquality: string}>) {
                return;
            }
        },
        /**
         * Hide/show certain filters based on data currently visible on chart
         */
        'chartVisibleData.stationNums': {
            deep: true,
            handler: function(stationNums: ChartVisibleData['stationNums']) {
                (this.embaymentStations as Array<{hide: boolean; station: EsriStation}>).forEach((st, i) => {
                    const shouldHide = stationNums.indexOf((st.station as any).Station_number.toString()) === -1;
                    Vue.set(this.embaymentStations[i], 'hide', shouldHide)
                });
            }
        },
        /**
         * Determine programs for station.
         * Reset embayment stations
         */
        selectedStation: {
            immediate: true,
            handler: function(station: StationInfo) {
                if (!station.esri) return;

                const abbrs = (this.$store.state as RootState).stationConstants?.programsAbbrv || {};
                this.embaymentStations = [];
                const stationPrograms = parseEsriStationPrograms(station.esri);
                this.monitoringPrograms = stationPrograms ? stationPrograms.map(program => ({
                    active: true,
                    visible: true,
                    program: abbrs[program] || program
                })) : [] as any;

                this.dqualities = (this.$store.state as RootState).stationConstants?.allQualities.map(dquality => ({
                    active: true,
                    visible: true,
                    dquality
                })) as any;
            }
        },
        /**
         * Determine programs for stations within embayment.
         * Determine embayment stations.
         */
        selectedEmbayment: {
            immediate: true,
            handler: async function(newV: EmbaymentInfo) {

                let embayID;

                if (!newV.esri) {
                    if (this.selectedStation) {
                        embayID = (this.selectedStation as StationInfo).esri?.Site_number.toString();
                    } else {
                        this.embaymentStations = [];
                        return;
                    }
                } else {
                    embayID = newV.esri.attributes.EMBAY_ID.toString();
                }

                const abbrs = (this.$store.state as RootState).stationConstants?.programsAbbrv || {};

                const stations = await getEsriStationsForEmbaymentId(embayID);
                this.embaymentStations = stations.map(station => ({
                    active: true,
                    visible: true,
                    station
                })) as any;

                const programs = getMergedStationsPrograms(stations).map(p => abbrs[p] || p) as any;
                this.monitoringPrograms = programs.filter(p=>!!p).map(program => ({
                    active: true,
                    visible: true,
                    program
                })) as any;

                this.dqualities = (this.$store.state as RootState).stationConstants?.allQualities.map(dquality => ({
                    active: true,
                    visible: true,
                    dquality
                })) as any;
            },
        },
    },
    methods: {
        selectStation(idx: number) {
            if (!this.embaymentStations[idx]) return;
            (this.embaymentStations[idx] as any).active = !(this.embaymentStations[idx] as any).active;
        },
        selectProgram(idx: number) {
            if (!this.monitoringPrograms[idx]) return;
            (this.monitoringPrograms[idx] as any).active = !(this.monitoringPrograms[idx] as any).active;
        },
        selectDquality(idx: number) {
            if (!this.dqualities[idx]) return;
            (this.dqualities[idx] as any).active = !(this.dqualities[idx] as any).active;

            const qualities = this.dqualities.filter((p: any) => p.active).map((p: any) => p.dquality);
            this.$store.commit('setChartSettings', {qualities});
        },
        resetZoom() {
            this.$store.commit('setChartSettings', {reset: !(this.$store.state as RootState).chartSettings.reset});
        }
    }
})
