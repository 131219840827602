























































































































































import { StationsConstants } from '@/lib/types';
import { RootState } from '@/store';
import Vue from 'vue'
import config from '@/config';
import { mapState } from 'vuex'
import { getMonth } from 'date-fns'
import { clearAllCache } from '@/lib/localCache';
import { getMergedStationsPrograms, getMergedStationsParams, getMergedStationsYearsForPrograms, getMergedStationsMonths } from '@/lib/utils';

export default Vue.extend({
    name: 'DataFilters',
    data() {
        return {
            // embaymentStations: [],

            parameters: (this.$store.state as RootState).stationConstants?.allParameters,
            selectedParameter: (this.$store.state as RootState).stationConstants?.defaultParameter,

            programs: (this.$store.state as RootState).stationConstants?.monitoringPrograms,
            selectedProgramsAbbr: [],

            // dQuality: (this.$store.state as RootState).stationConstants?.allQualities,
            // selectedQualities: [],

            // year: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)], 
            // year: ['2019-09-10', '2020-09-20'],
            year: ['2019', '2020'],
            selectedYears: [],
            months: [],
            selectedMonths: [],

            extents: ['Station', 'Embayment'],
            selectedExtent: 'Station',

            menuYear: false,
            menuMonths: false,

            monthsNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            tooltips: {
                delay: config.tooltips.delay,
                explore: config.tooltips.messages.dataExplore,
                param: config.tooltips.messages.dataParam,
                program: config.tooltips.messages.dataProgram,
                year: config.tooltips.messages.dataYear,
                month: config.tooltips.messages.dataMonths,
                quality: config.tooltips.messages.dataQuality,
                cache: 'Clear timeseries cache for all stations.'
            }
        }
    },
    props: ['stations', 'isEmbaymentSelected'],
    computed: mapState(['stationConstants']),
    watch: {
        menuYear(val) {
            const self = this as any;
            // This makes the first datepicker a year picker only
            val && this.$nextTick(() => (self.$refs.yearPicker.activePicker = 'YEAR'))
        },

        // ===== Data Filters Fields =====
        selectedParameter: {
            immediate: true,
            handler: function(newV) {
                this.$store.commit('setDataFilters', {params: newV});
            },
        },
        selectedYears(newV) {
            if (newV.length !== 2) return;
            this.$store.commit('setDataFilters', {years: newV});
        },
        selectedMonths(yyyyMM: string[]) {
            const monthsNums = yyyyMM.map(s => parseInt(s.split('-')[1]) - 1);
            monthsNums.sort((a,b) => a-b);
            this.$store.commit('setDataFilters', {months: monthsNums});
        },

        // ===== From Props =====
        stations: {
            immediate: true,
            handler: function(stations) {
                if (!stations.length) return;

                const embaymentPrograms = getMergedStationsPrograms(stations);
                this.programs = embaymentPrograms;
                this.selectedProgramsAbbr = this.programs as any;
                this.parameters = getMergedStationsParams(stations);

                const embayYears = getMergedStationsYearsForPrograms(stations, embaymentPrograms || []);
                this.year = this.determineMinMaxYears(embayYears);
                this.selectedYears = this.year as any;
                this.months = getMergedStationsMonths(stations) as any;
                this.selectedMonths = this.months.sort((a,b) => a-b).map((m: number) => `2020-${m < 10 ? ('0'+m) : m}`) as any;
            }
        },
        selectedProgramsAbbr: {
            immediate: true,
            handler: function(newV, oldV) {
                const embayYears = getMergedStationsYearsForPrograms(this.stations, this.selectedProgramsAbbr || []);
                this.year = this.determineMinMaxYears(embayYears);
                this.selectedYears = this.year as any;
                this.$store.commit('setDataFilters', {monitoringPrograms: newV});
                // this.$store.commit('setDataFilters', {monitoringPrograms: this.programs});
            }
        },
        stationConstants: {
            immediate: true,
            handler: function(newV: StationsConstants, oldV: StationsConstants) {
                // this.parameters = newV.allParameters as any;
                // this.selectedParameter = newV.defaultParameter;
                // this.programs = newV.monitoringPrograms as any;
                // this.dQuality = newV.allQualities as any;
            }
        }
    },
    methods: {
        determineMinMaxYears(yearsAr: string[]): string[] {
            if (yearsAr.length < 1) return [];
            if (yearsAr.length < 2) return [yearsAr[0], yearsAr[0]];

            const years = yearsAr.map(y => parseInt(y));
            return [Math.min(...years).toString(), Math.max(...years).toString()];
        },
        saveYear(date: number) {
            const self = this as any;

            if (this.selectedYears.length === 2) this.selectedYears = []; //reset

            const isFirstDate = this.selectedYears.length === 0;
            self.$refs.yearPicker.activePicker = 'YEAR';

            if (isFirstDate) {
                this.selectedYears = [date.toString()] as any;
            } else {
                this.selectedYears = this.selectedYears.concat(date.toString() as any);
                this.selectedYears = this.selectedYears.sort((a,b) => a-b);
                self.$refs.menuYear.save(this.selectedYears);
                this.menuYear = false;
            }
        },
        saveMonth(value) {
            const self = this as any;
            self.$refs.menuMonths.save(this.selectedMonths);
            this.menuMonths = false;
        },
        allowedMonths(val) {
            // return false;
            return (this.months as number[]).indexOf(parseInt(val.split('-')[1], 10)) !== -1
        },
        clearAppCache() {
            clearAllCache().then(() => {
                const currentStation = this.$store.state.selectedStation;
                const currentEmbayment = this.$store.state.selectedEmbayment;
                this.$store.commit('selectStation', currentStation);
                this.$store.commit('selectEmbayment', currentEmbayment);
            });
        }
    }
})
